@import 'editor/src/comonStyles/variables';

.productSwitcherButton {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
}

.productSwitcherContent {
  width: 287px;
  position: relative;
  margin-top: 9px;
  margin-left: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
  padding: 0;
}
