@import 'editor/src/comonStyles/variables';

.addonsListItemCategory {
  margin: 0 0 10px;

  .topBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .header {
      margin: 0;
      padding: 0;
      font-size: 12px;
      font-weight: normal;
      color: $black70;
    }

    .seeAll {
      font-size: 12px;
      color: $blue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .list {
    display: flex;
    flex-flow: row wrap;
  }
}
