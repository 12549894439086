@import 'editor/src/comonStyles/variables';

.wrapper {
  position: relative;
}

.selectLabel {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  margin-bottom: 4px;
  cursor: pointer;
  width: 300px;
}

.selectedOption {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid $neutral30;
  cursor: pointer;
  margin-bottom: 4px;

  .selectedOptionLabel {
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .chevronIcon {
    display: grid;
    margin-left: auto;
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;

    &.isOpen {
      transform: rotate(0deg);
    }
  }
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  // stylelint-disable-next-line number-no-trailing-zeros
  box-shadow: 0 2px 6px 2px rgba(33, 33, 33, 0.15), 0 1px 2px 0 rgba(33, 33, 33, 0.30);
  z-index: 1000;
  border-radius: 6px;
  padding: 8px;
  display: none;
  gap: 8px;
  overflow: hidden;
  transition: all 1s ease-in-out;
  height: 0;

  &.isOpen {
    display: grid;
    height: auto;
  }

  .option {
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;

    .content {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.32px;
      color: $neutral60;
    }

    &:hover {
      background: $neutral10;
    }
  }
}
