@import 'editor/src/comonStyles/variables';

.button {
  position: relative;
  overflow: visible;
}

.dropDownWrapper {
  position: absolute;
  top: 59px;
  left: 5px;
}

.dropDown {
  display: flex;
  flex-direction: column;
  justify-content: left;

  :global(.button) {
    justify-content: left;
    width: 100%;
    white-space: nowrap;

    > div:last-child {
      margin-right: 5px;
    }
  }
}

.dropDown:not(:global(.noButtonPadding)) {
  :global(.button) {
    padding: 0 5px;
  }
}

.iconPremium {
  width: 20px;
  margin-left: 5px;
}
