@import 'editor/src/comonStyles/variables';

.ConditionOptionsButton {
  display: flex;
  flex-wrap: wrap;
  margin: 2px -6px -6px;
}

.option {
  width: calc(50% - 12px);
  text-align: center;
  position: relative;
  display: inline-block;
  transition: border-color 100ms linear;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid rgba(51, 51, 51, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.48), 0 0 4px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  margin: 6px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    border-color: #212121;
  }

  &.active {
    border-color: #212121;
    background-color: $neutral10;
    color: #212121;
  }

  .check {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 12px;
    height: 12px;
    background-color: $blue;
    border-radius: 6px;
    padding: 2px;

    svg {
      width: 100%;
      height: 100%;
      fill: white;
      stroke: white;
    }
  }

  &.active .check {
    background-color: white;

    svg {
      fill: $blue;
      stroke: $blue;
    }
  }
}
