@import 'editor/src/comonStyles/variables';

.CheckboxElement {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $almostBlack;
  border: 1px solid $black10;
  border-radius: 6px;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 10px;

  &.active, &.enabled:hover {
    background-color: $blue10;
    border: 1px solid $signalBlue;
  }
}

.unavailable {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #d0412d;
}
