@import 'editor/src/comonStyles/index';

.removeImageButtonWrap {
  width: 100%;
  height: 48px;

  .removeImageButton {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: left;
    padding: 16px;
  }
}

