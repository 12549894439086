@import 'editor/src/comonStyles/index';
@import 'editor/src/comonStyles/animations';

.uploadingBadgeWrap {
  width: 100%;

  .uploadingBadge {
    background: transparent;
    border: 1px solid rgba($black10, 0.7);
    margin: 5px 0 0;
    border-radius: 2px;
    height: 4px;
    overflow: hidden;
    width: calc(100% - 2px);

    .progress {
      background: $blue;
      height: 4px;
      width: 0;
    }

    .infiniteProgress {
      background: $blue;
      height: 4px;
      width: 100%;
      animation: infiniteLoaderAnimation 1s infinite linear;
      transform-origin: 0 50%;
    }
  }
}
