@import 'editor/src/comonStyles/variables';

.controlTitleContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;
}

.controlTitle {
  color: $black;
  margin: 12px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
