@import 'editor/src/comonStyles/variables';

.spreadPreview {
  position: relative;
  background: $white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  justify-items: center;
  margin: 0 40px; // default button width

  .image {
    position: relative;
    display: flex;
    border: 2px solid $white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity 200ms linear;

    &.active { border-color: $primary100; }
    &.notLoaded { opacity: 0; }

    img {
      width: 100%;
    }
  }

  .dragAndDropWrapper {
    touch-action: none;
  }

  .dragAndDrop {
    position: absolute;
    top: 0;
    left: -40px; // default button width
    background-color: transparent;
    color: $neutral60;

    svg {
      stroke: none;
    }
  }

  .name {
    display: flex;
    justify-content: space-around;
    width: 100%;
    color: $neutral80;
    font-size: 14px;
    margin: 2px 0;
  }
}

.dividerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 40px;

  .addButton {
    flex-shrink: 0;
  }

  .divider {
    flex-shrink: 1;
    flex-grow: 1;
    border-top: 1px solid $neutral40;
    max-width: 30vw;
  }
}
