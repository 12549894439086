.button {
  display: flex;
  align-items: center;
  position: relative;

  .iconContainer {
    position: relative;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    .iconPremiumBox {
      position: absolute;
      right: -6px;
      top: -2px;
  
      .iconPremium {
        width: 20px;
      }
    }

  }

  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    flex: 1 0 0;
  }

  &.labelIcon .label {
    margin-right: 5px;
  }

  &.labelIconVertical {
    display: flex;
    flex-direction: column;
    padding: 30px 4px;

    .label {
      font-family: Roboto, 'sans-serif';
      font-size: 11px;
      font-weight: 500;
      line-height: 14px;
    }
  }

  &.label .label {
    text-align: center;
    padding: 0 5px;
  }
}
