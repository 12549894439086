@import 'editor/src/comonStyles/variables';

.unlockIcon, .lockIcon {
  flex-shrink: 0;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 2px 4px;
  cursor: pointer;
  min-height: 28px;
  align-items: center;
  display: flex;

  &.disabled {
    opacity: 0.45;
    pointer-events: none;
  }
  svg {
    height: 18px;
    width: 18px;
  }
}

.lockIcon {
  border-color: #212121;
  background-color: $neutral10;
}
