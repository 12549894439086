@import 'editor/src/comonStyles/variables';

.statusWrapper {
  font-family: 'Gelato Sans', sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
  color: $neutral70;
  animation: animationOnUpdate 500ms;
  transform-origin: 100% 0;
  padding: 12px;

  .status {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: stretch;
  }
}

.statusIcon {
  svg {
    fill: $neutral80;
    stroke: $neutral80;
  }

  &.loadingStatus {
    svg {
      fill: none;

      > * {
        stroke: $neutral80;
      }
    }
  }
}

@keyframes animationOnUpdate {
  0% {
    color: $neutral60;
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    color: $neutral60;
  }
}
