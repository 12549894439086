@import 'editor/src/comonStyles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;


  @media only screen and (max-width: $maxMobileWidth) {
    max-height: 75vh;
    padding: 0 16px;
  }
}

.backButton:hover {
  text-decoration: underline;
  cursor: pointer;
}

.attributionImage {
  align-self: stretch;
  flex: 1 0 0;
  margin: 0 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% center;
}

.prompt {
  margin: 12px 0;
}

.placeholder {
  flex: 1 0 0;
}
