@import 'editor/src/comonStyles/variables';

.badge {
  background: $signalOrange;
  color: $white;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 5px;
}
