.BackHeader {
  flex-shrink: 0;
  height: 56px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid whitesmoke;

  &:hover {
    opacity: 0.5;
  }

  .icon {
    margin-right: 15px;
    flex-shrink: 0;
  }

  .title {
    font-size: 21px;
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
