@import 'editor/src/comonStyles/variables';

.icon {
  fill: $almostBlack;
  stroke: $almostBlack;
  display: inline-flex;
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
