.sidebarLayout {
  width: 100%;
  height: 100%;

  .mainContent {
    width: 100%;
    height: 100%;
    display: flex;
  }
}
