@import 'editor/src/comonStyles/variables';

.Dimension {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border: 2px solid $neutral70;
  border-radius: 8px;
  margin-bottom: 8px;
  width: 100%;

  &:focus-within {
    border-color: $black;
  }

  .dimensionInput {
    font-family: "Gelato Sans";
    flex-grow: 1;
    margin-left: 12px;
    border: none;
    outline: none;
  }

  .unit {
    font-family: "Gelato Sans";
    font-size: 14px;
    color: #999;
  }

  input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }

  input {
    font-size: 14px;;
  }
}
