@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.back {
  align-self: stretch;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: color.mix($white, $primary100, 95%);
  }
}

.title {
  font-size: 21px;
}
