@import 'editor/src/comonStyles/variables';

.section {
  background: $black5;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;

  &.noBackground {
    background: transparent;
  }
}
