@import 'editor/src/comonStyles/index';

.errorBadge {
  background: $white;
  border: 1px solid $red;
  margin-right: 3px;
  padding: 1px 5px;
  border-radius: 7px;
  height: 14px;
  font-size: 10px;
  line-height: 10px;
  color: $red;
  pointer-events: all;
  display: inline-block;
}
