@import 'editor/src/comonStyles/variables';

.MockupCustomSizeController {
  display: grid;
  grid-template:
    'width lock' auto
    'height lock' auto
    / auto auto;

  .inputWrap {
    display: flex;
  }

  .inputWrap + .inputWrap {
    margin-top: 6px;
  }

  .lock {
    margin-left: 6px;
    grid-area: lock;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon {
      border: 1px solid transparent;
      border-radius: 6px;
      margin: 4px 0;
    }

    &.on, &:hover {
      .icon {
        background-color: $neutral10;
        border-color: #212121;
      }
    }

    .bar { opacity: 0; }
    &.on .bar { opacity: 1; }
  }

  .icon {
    padding: 8px;
  }
}
