.AutofillMobileMenu {
  height: 85vh;
  display: flex;
  flex-direction: column;
  
  .content {
    padding: 16px;
    flex: 1 0 0;
    overflow-y: auto;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }
}

.title {
  font-family: "Gelato Sans", sans-serif;
  font-size: 22px;
  margin: 0;
  flex: 1;
}
