@import 'editor/src/comonStyles/variables';

.GridDesignButton {
  margin-top: 12px;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }

  &:hover {
    background-color: rgba(34, 34, 34, 0.05);
  }

  &.selected {
    background-color: $neutral10;
    border-color: #212121;
  }
}
