@import 'editor/src/comonStyles/variables';

.backButton {
  height: 56px;
  margin-bottom: 1px;
}

.header {
  .resetButtonContainer {
    flex: 1;
    margin-right: 0;
  }

  .resetButton {
    height: 56px;
  }

  .closeBtn {
    flex: 0 0 0;
  }
}

.separator {
  margin-bottom: 6px;
  height: 1px;
  background: #e7e9eb;
}

.filtersContainer {
  overflow: auto;
}

.actionBar {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  padding: 6px 12px;
}

.actionButton {
  min-width: 90px;
}
