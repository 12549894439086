@import 'editor/src/comonStyles/variables';

.PersonalizationText {
  background: $neutral5;
  border: 1px solid $lightGrey;
  border-radius: 6px;

  &.building {
    cursor: grab;

    &.active {
      border-color: #212121;
      background-color: $neutral10;
    }
  }

  .textContainer {
    border-top: 1px solid $lightGrey;
    padding: 8px;

    &.isBuilding {
      margin: 12px;
    }
  }

  .input {
    padding: 10px 12px;
    border: 1px solid rgba(51, 51, 51, 0.2);
    border-radius: 3px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #333;

    &:focus {
      border-color: $black;
      outline-color: $black;
    }
  }

  .charCounter {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
  }

  &:hover .menuBtn {
    opacity: 1;
  }

  .textOptions {
    padding: 0 8px;

    .textOptionsHeader {
      font-size: 12px;
      font-weight: 700;
    }
  }

  .characterLimitOption {
    .switchControl {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .characterLimitOptionTitle {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .characterLimitInputContainer {
      margin-top: 12px;
      max-height: 0;
      opacity: 0;
      transition: max-height 0.2s ease-out, opacity 0.2s ease-out;

      &.visible {
        max-height: 100px;
        opacity: 1;
      }

      .characterLimitInput {
        height: 40px;
        width: 120px;
        border: 1px solid $lightGrey;
        border-radius: 8px;
        padding-left: 12px;
        font-size: 14px;
      }
    }
  }
}

.menuBtn {
  opacity: 0;
}
