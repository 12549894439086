@import 'editor/src/comonStyles/variables';

.checkbox {
  display: inline-flex;
  flex-direction: row;
  place-items: center center;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .check {
    border: 1px solid #212121;
    background: $white;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    color: $white;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;

    svg {
      margin: 0;

      > * {
        fill: $white;
        stroke: $white;
      }
    }

    &.on {
      background: #212121;
    }
  }

  &.disabled .check {
    cursor: not-allowed;
  }

  .label {
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: $almostBlack;
    margin-left: 7px;
    display: inline-flex;
    white-space: nowrap;
    text-wrap: wrap;
  }
}
