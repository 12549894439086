.infoBanner {
    padding: 8px;
    display: flex;
    border-radius: 8px;
    border: 1px solid #51C0FF;
    background-color: #EEF1FB;
    color: #00527C;
    margin-top: 8px;
    column-gap: 8px;
    letter-spacing: 0.24px;
    line-height: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  
  .icon {
    path {
      fill: #007CB4;
      stroke: #007CB4;
    }
  }
  
  .iconClose {
    cursor: pointer;
    width: 12px;
    height: 12px;
    margin-top: 2px;
    margin-right: 2px;
  }
  
  .description {
    a {
      color: #007CB4;
    }
  }