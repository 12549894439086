.ConditionOptionsThumbnail {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.option {
  padding: 5px;
  display: flex;
  transition: border-color 100ms linear;
  cursor: pointer;
  border: 1px solid transparent;
  width: 40px;
  height: 40px;

  &:hover {
    border-color: rgba(205, 205, 205, 1);
  }

  &.active {
    border-color: rgba(205, 205, 205, 1);
  }
}

.option + .option {
  margin-left: 10px;
}
