@import 'editor/src/comonStyles/variables';

.ProductInfo {
  margin-top: 11px;
}

.productDescription {
  font-size: 14px;
  color: $almostBlack;
  margin-top: 24px;

  p {
    margin: 0 0 1em;
    line-height: 1.4285em;
  }
}
