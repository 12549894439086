@import 'editor/src/comonStyles/variables';

.buttonMenuMoreWrap {
  position: relative;

  .menu {
    position: absolute;
    top: 40px;
    right: 0;
  }

  .badge {
    top: 2px;
    right: 2px;
  }
}
