@import 'editor/src/comonStyles/variables';

.ImageThumbnail {
  border-radius: 3px;
  border: 2px solid transparent;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.48), 0 0 4px rgba(0, 0, 0, 0.12);
  width: 56px;
  height: 56px;
  margin: 2px;
  cursor: pointer;
  position: relative;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 100ms linear;

    &.loaded {
      opacity: 1;
    }
  }

  &.active {
    border-color: $primary100;
  }

  &:hover {
    border-color: $primary100;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate3d(-50%, -50%, 0);
    animation: fadeInAnimation ease 200ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
