@import 'editor/src/comonStyles/index';
@import 'editor/src/comonStyles/animations';

.allFilesUploadProgress {
  color: $almostBlack;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .progressBackground {
    width: calc(100% + 22px);
    height: 3px;
    background: $black10;
    margin: 0 -11px;
    overflow-x: hidden;

    .progressBar {
      background: $blue;
      height: 3px;
      transition: width 200ms;
    }

    .progressBarInfinite {
      background: $blue;
      height: 3px;
      width: 100%;
      animation: infiniteLoaderAnimation 1s infinite linear;
      transform-origin: 0 50%;
    }
  }

  .progressText {
    margin: 5px 0;
    display: flex;

    .iconLoading {
      display: flex;
      justify-content: center;
      margin: 10px;
    }

    .captions {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 12px;

      .line1 {
        color: $almostBlack;
      }

      .line2 {
        color: $black50;

        .mbytes {
          margin-left: 5px;
        }
      }
    }
  }
}
