@import 'editor/src/comonStyles/variables';

.Slider {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  padding: 0 12px;
  touch-action: none;

  .rail {
    width: 100%;
    position: relative;
    height: 8px;
    border-radius: 6px;
    background-color: #e9e9e9;
    border: 1px solid transparent;
  }

  &:hover .rail {
    border-color: #cdcdcd;
  }

  .track {
    width: 100%;
    height: 100%;
    background-color: $blue;
    border-radius: 6px;
    transform-origin: 0 0;
  }

  .handle {
    position: absolute;
    top: 50%;
    left: 0;
    width: 24px;
    height: 24px;
    transform: translate3d(-50%, -50%, 0);
    margin-top: -1px;
    cursor: grab;
    border-radius: 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);

    &:hover {
      border-color: rgba(51, 51, 51, 0.5);
    }
  }

  &.down .handle {
    cursor: grabbing;
    border-color: $blue;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}
