@import '../../comonStyles/variables';

.title {
  margin-bottom: 16px;
  font-size: 16px;
  padding: 9px 0;
}

.content {
  max-height: 435px;
  overflow: auto;
}

.richTooltip {
  font-size: 14px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid $lightGrey;
  box-shadow: 0 8px 16px 0 rgba(33, 33, 33, 0.16), 0 2px 4px 0 rgba(33, 33, 33, 0.12);
  background: #fff;
  user-select: none;
  margin-left: 14px;
  overflow: visible;
  min-width: 240px;
  width: 380px;
  color: $neutral90;
  line-height: 21px;

  img {
    max-width: 100%;
    margin: 16px 0;
  }

  ol {
    padding-left: 20px;
    font-weight: 700;

    ul {
      padding-left: 18px;
    }
  }

  p, h4 {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    margin-top: 5px;
  }

  ul {
    font-weight: 400;
    list-style: disc;
    padding-left: 20px;
    margin-top: 5px;
    margin-bottom: 0;

    li {
      margin-bottom: 5px;
    }
  }

  .pointer {
    position: absolute;
    left: -10px;
    top: 24px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 10px solid #fff;
  }

  .bottomBar {
    margin-top: 16px;
    height: 40px;
    display: flex;
    justify-content: flex-end;

    .actionButton {
      border: none;
      font-size: 14px;
    }
  }
}

.linkButton {
  color: $info50;
}
