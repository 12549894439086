@import 'editor/src/comonStyles/variables';

.buttonMenuMore {
  position: relative;

  .badge {
    top: 2px;
    right: 2px;
  }
}

.dropDown {
  position: absolute;
  bottom: -8px;
  right: 0;
}

.itemList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  :global(svg) {
    width: 24px;
    height: 24px;
  }
}
