@import 'editor/src/comonStyles/variables';

.settingsResetButtonWrapper {
  margin-top: 20px;

  .settingsResetButton {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 5px 0;
  }
}
