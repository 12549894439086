@import 'editor/src/comonStyles/variables';

.SceneItem {
  width: 91px;
  height: 100px;
  cursor: pointer;
  margin: 2px;
  border: 2px solid transparent;
  object-fit: cover;

  &:hover {
    opacity: 0.8;
  }

  &.selected {
    border-color: $blue;
  }
}
