@import 'editor/src/comonStyles/variables';

$loading-icon-size: 35px;

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
  display: flex;
}

.loadingIcon {
  margin: auto;

  svg {
    width: $loading-icon-size;
    height: $loading-icon-size;

    > * {
      stroke: $neutral80;
    }
  }
}
