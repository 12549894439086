@import 'editor/src/comonStyles/variables';

.PersonalizationShape {
  background: $neutral5;
  border: 1px solid $lightGrey;
  border-radius: 6px;

  &.building {
    cursor: grab;

    &.active {
      border-color: #212121;
      background-color: $neutral10;
    }
  }
}
