@import 'editor/src/comonStyles/variables';

.controlButton {
  min-width: 40px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  font-size: 13.3333px;
  font-weight: 400;
  user-select: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:not(.disabled, .mobile):hover {
    background-color: $buttonHoverBackground;
    border-color: $buttonHoverBackground;

    &:active {
      background-color: $buttonActiveBackground;
      border-color: $buttonActiveBorder;
    }
  }

  &.on {
    background-color: $neutral10;
    border-color: $primary100;

    &:not(.disabled, .mobile):hover {
      background-color: $buttonActivatedHoverBackground;
      border-color: $primary100;

      &:active {
        background-color: $neutral10;
        border-color: $primary100;
      }
    }
  }

  &.semitransparent {
    color: rgba($white, 0.9);

    &:not(.disabled, .mobile):hover {
      background-color: rgba($almostBlack, 0.1);
      border-color: rgba($almostBlack, 0.2);

      &:active {
        background-color: rgba($almostBlack, 0.1);
        border-color: rgba($almostBlack, 0.2);
      }
    }

    &.on {
      background-color: rgba($almostBlack, 0.1);
      border-color: rgba($almostBlack, 0.2);

      &:not(.disabled):hover {
        background-color: rgba($almostBlack, 0.1);
        border-color: rgba($almostBlack, 0.2);

        &:active {
          background-color: rgba($almostBlack, 0.1);
          border-color: rgba($almostBlack, 0.2);
        }
      }
    }
  }
}
