@import 'editor/src/comonStyles/variables';

.SelectButtonGroup {
  border-radius: 6px;
  border: 1px solid $black10;
  padding: 4px;
  display: flex;
  height: 38px;

  &.disabled {
    opacity: 0.5;
  }

  &.fullWidth {
    width: 100%;
  }
}
