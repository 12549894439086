@import 'editor/src/comonStyles/variables';

.addonsList {
  .list {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -3px;

    .item {
      width: calc(25% - 6px);
      margin: 3px;
      height: 70px;
      position: relative;
      background: $black10;
      background-image: linear-gradient(to right, transparent 33%, rgba(255, 255, 255, 0.5) 50%, transparent 66%);
      animation: shine 2s infinite;
      background-size: 300% 100%;
    }
  }
}

@keyframes shine {
  0% {
    background-position: right;
  }

  100% {
    background-position: left;
  }
}
