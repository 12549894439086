@import 'editor/src/comonStyles/variables';

.ControlsSection {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75px;
  z-index: 3;
  overflow: hidden;
  pointer-events: none;

  .scroller {
    pointer-events: auto;
    background-color: $white;
    transition: transform 200ms linear, opacity 200ms linear;
    opacity: 0;
    transform: translateY(100%);

    .sectionContent {
      display: flex;
      align-items: center;

      > * {
        margin: 4px;
      }

      > *:first-child {
        margin-left: 0;
      }
    }
  }

  &.open .scroller {
    transform: translateY(0%);
    opacity: 1;
  }

  &.close .scroller {
    transition: transform 400ms linear, opacity 400ms linear;
    transform: translateY(100%);
    opacity: 0;
  }
}

.closeBtn {
  position: sticky;
  left: 0px;
  height: 55px;
  background: white;
  z-index: 2;
  border-right: 1px solid $black10;
}

.scroller {
  height: 100%;
}
