@import 'editor/src/comonStyles/variables';

$text-size-input-width: 60px;

.mainContainer {
  display: flex;
  align-items: center;

  :global(.icon svg) {
    max-width: 24px;
    max-height: 24px;
  }

  @media only screen and (max-width: $maxMobileWidth) {
    padding: 12px 12px 0;
  }
}

.input {
  width: $text-size-input-width;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #3333;
  text-align: center;
}

.slider {
  min-width: 250px;
  flex-grow: 1;
}

.singleRow {
  flex-wrap: nowrap;

  .slider {
    margin: 0 10px;
  }
}

.twoRows {
  flex-wrap: wrap;
  justify-content: space-between;

  .label {
    flex-basis: calc(100% - $text-size-input-width);
  }

  .slider {
    order: 3;
    padding: 0;
    margin-top: 6px;
  }
}
