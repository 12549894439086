@import 'editor/src/comonStyles/variables';

.dropdown {
  display: flex;
  justify-content: left;
  min-width: 250px;
  flex-direction: column;
  text-align: left;

  .disabled {
    opacity: 0.5;
  }
}

.wrapper {
  margin-top: 12px;
}

.optionsWrapper {
  display: flex;
  flex-direction: column;

  p {
    font-size: 12px;
    margin: 5px 0 0;
  }
}

.persoCheckboxWrap, .persoToggleWrap {
  &.disabled {
    pointer-events: none;
  }
}

.persoToggleWrap {
  .toggleControl {
    display: flex;
    align-items: center;
  
    .label {
      margin-left: 7px;
    }
  }

  .description {
    margin-top: 5px;
  }
}



.separator {
  border-bottom: 1px solid $black5;
  width: 100%;
  margin: 16px 0;
}
