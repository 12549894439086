@import 'editor/src/comonStyles/variables';

.TabContents {
  width: 310px;
  height: 100%;
  padding: 0 11px;
  display: flex;
  flex-direction: column;

  &.mobile {
    width: 100%;
    touch-action: none;
    padding: 0 12px;
  }
}
