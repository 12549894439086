@import 'editor/src/comonStyles/variables';

.applyToAll {
  padding: 11px;
}

.applyToAllButton {
  width: 100%;
  justify-content: center;
}
