@import 'editor/src/comonStyles/variables';

.inputWrap {
  position: relative;

  .input {
    width: 100%;
    height: 38px;
    background: $white;
    border: 1px solid rgba($black, 0.1);
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 0 10px;
    outline: none;

    &::-webkit-outer-spin-button,&::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &[type='number'] {
      appearance: textfield; /* Firefox */
    }

    &.withLabel {
      padding-right: 50px;
    }

    &:focus {
      border-color: $blue;
    }

    &.disabled {
      background-color: $black5;
      opacity: 0.5;
    }
  }

  .label {
    position: absolute;
    height: 38px;
    line-height: 38px;
    right: 10px;
    top: 0;
    width: auto;
    margin-left: auto;
    color: rgba($black, 0.5);
  }
}
