@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.ProductSize {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $almostBlack;
  border-radius: 6px;
  align-items: center;
  width: 100%;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
  }

  .ctaButton {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    font-family: 'Gelato Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 24px;
  }

  .more {
    margin-left: 10px;
    grid-column: 2;
    grid-row: 1 / 3;
  }

  .times {
    margin: 0 3px;
  }

  .unit {
    margin-left: 3px;
  }

  .times, .unit {
    color: color.adjust($almostBlack, $alpha: -0.3);
  }

  .error {
    height: 28px;
    flex: 1 0 0;
    grid-column: 1;
    grid-row: 2;
    font-size: 10px;
    line-height: 12px;
    color: $red;
    padding-bottom: 9px;
  }
}
