.SkeletonBlock {
  overflow: hidden;
  background-color: #f5f5f5;

  &::after {
    @keyframes passby {
      from { transform: translateX(-200%); }
      to { transform: translateX(200%); }
    }

    content: '';
    display: block;
    background:
      linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      );
    width: 100%;
    height: 100%;
    animation-duration: 1600ms;
    animation-iteration-count: infinite;
    animation-name: passby;
    animation-timing-function: linear;
  }
}
