@import 'editor/src/comonStyles/variables';

.ProductVariantList {
  padding: 0 11px;
  width: 100%;
  height: 100%;

  &.linkDisabled {
    padding-top: 9px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 9px;
  font-size: 14px;
  color: $almostBlack;
}

.list {
  position: relative;
}

.separator {
  margin: 0 10px;
  height: 1px;
  background: #e7e9eb;
}
