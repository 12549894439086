@import 'editor/src/comonStyles/variables';

.element {
  width: 100%;
  height: 100%;
  background: $black5;
  background: $black10;
  background-image: linear-gradient(to right, transparent 33%, rgba(255, 255, 255, 0.5) 50%, transparent 66%);
  animation: shine 2s infinite;
  background-size: 300% 100%;
}

@keyframes shine {
  0% {
    background-position: right;
  }

  100% {
    background-position: left;
  }
}
