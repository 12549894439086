@import 'editor/src/comonStyles/index';

.carousel {
  width: 100%;
  flex: 1 0 0;
  position: relative;
  overflow: hidden;
  padding: 20px;

  &.loaded .images {
    opacity: 1;
  }

  .images {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    opacity: 0;
    will-change: transform opacity;
    transition: transform ease-in-out 500ms, opacity 300ms linear;
  }

  .arrowLeft, .arrowRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgba($black90, 0.7);
    border-radius: 8px;

    svg {
      stroke: $white;

      path {
        fill: $white;
      }
    }

    &:hover {
      background: rgba($black90, 0.5);
    }

    &:active {
      background: rgba($black90, 0.9);
    }
  }

  .arrowLeft {
    left: 10px;
  }

  .arrowRight {
    right: 10px;
  }
}

.loaderContainer {
  position: absolute;
  top: calc(50% - 2.5vh);
  left: calc(50% - 2.5vh);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 5vh;
    height: 5vh;

    > * {
      stroke: $neutral80;
    }
  }
}
