@import 'editor/src/comonStyles/variables';

.colorTile {
  border-radius: 200px;
  border: 1px solid var(--black-alfa-black-alfa-10, rgba(0, 0, 0, 0.10));
  background: #FAF7EA;
  width: 28px;
  height: 28px;
  margin-right: 4px;
}

.colorItem {
  &:not(:nth-child(-n+2)) {
    margin-top: 8px;
  }
}

.colorList {
  margin: 16px 0;
  display: grid;
  grid-template-columns: repeat(2, 140px);
  justify-content: space-between;

  .colorItemSubtitle {
    color: #6B6B6B;
  }
}
