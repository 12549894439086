.FileDropZone {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: stretch;
  transition: opacity 100ms linear;

  &.over {
    opacity: 0.1;
  }
}

.dropZone {
  width: 100%;
  pointer-events: none;
}
