@import 'editor/src/comonStyles/variables';

.warningBanner {
  padding: 8px;
  margin: 12px;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: $neutral10;
  border-radius: 3px;

  .icon {
    grid-area: icon;
    margin-right: 6px;
    width: 24px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 0 6px;
  }

  .iconClose {
    cursor: pointer;
    width: 12px;
    height: 12px;
    margin-top: 2px;
    margin-right: 2px;
  }
}