@import 'editor/src/comonStyles/variables';

.PersonalizationCondition {
  border-radius: 6px;
  border: 1px solid $rulerBorderColor;
  background-color: $rulerBackgroundColor;

  .name {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .condition {
    padding: 12px;
  }

  .content {
    cursor: grab;
    position: relative;

    &:hover .menuBtn {
      opacity: 1;
    }
  }

  &.isEditing {
    border-color: #212121;
    background-color: $neutral10;
  }
}

.menuBtn {
  opacity: 0;
}

.editIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 24px;
}

.button {
  svg {
    margin-right: 4px;
  }
}

.conditionElement {
  border: 1px solid transparent;
}
