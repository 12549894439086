@import 'editor/src/comonStyles/variables';

.search {
  position: relative;
  flex-grow: 1;

  input {
    width: 100%;
    height: 40px;
    padding: 5px 5px 5px 40px;
    border: 1px solid $black30;
    color: $almostBlack;
    border-radius: 8px;
    font-size: 16px;

    ::placeholder {
      color: $black70;
    }

    &:focus {
      border-color: $blue;
      outline: none;
    }
  }

  .icon {
    position: absolute;
    left: 5px;
    top: 5px;
    fill: $black70;
  }

  .clear {
    position: absolute;
    right: 10px;
    top: 13px;
    cursor: pointer;

    svg {
      stroke: $black30;
    }

    &:hover svg {
      stroke: $black70;
    }
  }
}
