.ImagePicker {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.images {
  flex: 1 0 0;
  overflow-y: auto;
}

.noImages {
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imageText {
  margin-bottom: 12px;
}

.image {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: opacity 100ms linear;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.back {
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: rgba(51, 51, 51, 0.2);
  }
}
