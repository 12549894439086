@import 'editor/src/comonStyles/variables';

.applyToAll {
  padding: 11px;
}

.applyToAllButton {
  width: 100%;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.gridContentHeader {
  svg {
    margin-right: 16px;
  }
}
