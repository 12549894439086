@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.filterItem {
  padding: 5px;

  &.active {
    .border {
      border-color: #212121;
      background: color.mix($neutral10, $white, 10%);
    }
  }

  &.mobile {
    width: 100%;
    padding: 0;

    .border {
      display: none;
    }
  }

  &.rowActive {
    padding-bottom: 60px;
  }

  .border {
    border: 1px solid $white;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      border-color: #212121;
    }

    .imagePlaceholder {
      width: calc(100% - 4px);
      margin: 2px;
      position: relative;
      background: $black10;
      border-radius: 5px;

      .premium {
        position: absolute;
        bottom: 2px;
        left: 2px;
      }

      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      .image {
        border-radius: 5px;
        width: 100%;
        position: absolute;
        animation: fadeInAnimation ease 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }

    .label {
      font-size: 12px;
      color: $almostBlack;
      width: 100%;
      padding: 0 3px;
      margin-bottom: 5px;
      display: block;
      text-align: center;
    }

    .sliderLabel {
      font-size: 12px;
      color: $almostBlack;
    }
  }

  &:not(.mobile) .options {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .slider {
      flex-grow: 1;
      padding: 0 5px;
    }

    .input {
      border: 1px solid $black10;
      border-radius: 3px;
      width: 61px;
      height: 40px;
      padding: 0 5px;
      text-align: center;
    }
  }

  &.mobile .options {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .slider {
      flex-grow: 1;
      padding: 0 15px;
    }

    .input {
      border: 1px solid $black10;
      border-radius: 3px;
      width: 61px;
      height: 40px;
      padding: 0 5px;
      text-align: center;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
