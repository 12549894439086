@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.ElevatePrompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-family: 'Gelato Sans', sans-serif;

  .description {
    color: #525252;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
  }

  .learnMore {
    color: #6395df;
    margin: 8px 0;
  }

  .button {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 24px;
    border: 2px solid #bdbdbd;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.24px;

    &:hover {
      background: color.mix($white, $black, 95%);
    }
  }
}
