@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.PlusPrompt {
  padding: 12px;
  border: 1px solid $ube;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  font-size: 12px;

  .header {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      fill: black;
      stroke-width: 0;
    }
  }

  .text {
    flex: 1 0 0;
    font-weight: bold;
  }

  .premium {
    flex: 1 0 0;
    margin-left: 5px;
  }

  .description {
    margin: 12px 0;
    line-height: 16px;
  }

  .button {
    background-color: $ube;
    color: white;
    cursor: pointer;
    border-radius: 6px;
    font-size: 16px;
    line-height: 18px;
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: color.mix($ube, white, 90%);
    }
  }
}
