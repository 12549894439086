@import 'editor/src/comonStyles/variables';

.ColorThumbnail {
  border-radius: 3px;
  border: 2px solid transparent;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.48), 0 0 4px rgba(0, 0, 0, 0.12);
  width: 56px;
  height: 56px;
  margin: 2px;
  cursor: pointer;

  &.active {
    border-color: $blue;
  }

  &:hover {
    border-color: $blue;
  }
}
