@import 'editor/src/comonStyles/variables';

.infoIconContainer {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
  border-radius: 50%;
  pointer-events: auto;
  cursor: pointer;

  .infoIcon {
    path {
      fill: $neutral70;
      stroke: $neutral70;
    }
  }
}
