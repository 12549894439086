@import 'editor/src/comonStyles/index';

.Preview {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  .bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .Popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    background: $white;
    display: grid;
    grid-template:
      'topMenu topMenu topMenu' auto
      'sidebar preview bar' 1fr
      / auto 1fr auto;
  }

  &.desktop .Popup {
    height: 1000px;
    max-height: 90%;
    width: 1200px;
    max-width: 90%;
    border-radius: 6px;
    overflow-y: auto;
  }

  .topMenu {
    display: flex;
    grid-area: topMenu;
    border-bottom: 1px solid $black5;
    align-items: center;
  }

  .title {
    flex: 1 0 0;
    display: flex;
  }

  .errorMessage {
    width: 80%;
    height: 80%;
    margin: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: preview;

    h2 {
      font-size: 36px;
      color: $almostBlack;
    }

    p {
      font-size: 15px;
      color: $almostBlack;
    }
  }
}

.spinner {
  grid-area: preview;
}
