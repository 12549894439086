@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.tab {
  background: $tabBackground;
  width: 80px;
  height: 66px;
  min-height: 66px;
  cursor: pointer;
  position: relative;
  border-right: 1px solid $black5;
  padding: 0 4px;

  .tabContent {
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    width: 72px;
    height: 100%;
  }

  * {
    stroke: #525252;
  }

  [fill^='#'] {
    fill: #525252;
  }

  &:hover {
    background: $workspaceBackground;
    background: color.mix($white, $primary100, 95%);
    color: #212121;

    * {
      stroke: #212121;
    }

    [fill^='#'] {
      fill: #212121;
    }

    .title {
      color: #212121;
    }
  }

  &.mobile {
    background: transparent;
    border-right: none;
  }

  &.active {
    background-color: $white;
    border-right: 1px solid $white;
    cursor: default;
    color: #212121;

    > * {
      stroke: #212121;
    }

    .title {
      color: #212121;
      font-weight: 500;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;

    &:hover {
      background: $white;
    }
  }

  .icon {
    width: 32px;
    height: auto;
  }

  .title {
    font-style: normal;
    font-size: 11px;
    line-height: 16px;
    color: #525252;
    margin-top: 2px;
    font-family: 'Gelato Sans', sans-serif;
  }
}
