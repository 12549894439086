@import 'editor/src/comonStyles/variables';

.wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  overflow: visible;

  .button {
    background: none;
    border: none;
    color: white !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}
