@import 'editor/src/comonStyles/variables';

.buttonControlOption {
  flex-basis: calc(50% - 3px);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $almostBlack;
  border: 1px solid $black10;
  border-radius: 6px;
  padding: 6px 12px;

  &.active, &.enabled:hover {
    background-color: $blue10;
    border: 1px solid $signalBlue;
  }
}
