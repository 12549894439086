@import 'editor/src/comonStyles/variables';

.Badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  background: rgba(89, 145, 255, 0.3);
  padding: 0;
  height: 24px;
  width: 24px;

  &.noBackground {
    background: transparent;
  }

  > div {
    max-width: 16px;
    max-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}
