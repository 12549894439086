@import 'editor/src/comonStyles/index';

.wrapper {
  display: flex;
  gap: 4px;
  background-color: $neutral5;
  padding: 4px;
  border-radius: 12px;
  max-width: fit-content;
}

.optionButton {
  padding: 6px 8px;
  color: $neutral70;
  border-radius: 8px;

  &:hover, &.selected {
    background-color: $black10;
    color: $primary100;
  }
}
