@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.fittingItem {
  padding: 5px;
  flex: 1 0;
  max-width: 96px;

  &.active {
    .border {
      border-color: $primary100;
      background: color.mix($primary100, $white, 10%);
    }
  }

  .border {
    border: 1px solid $white;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      border-color: $primary100;
    }

    .imagePlaceholder {
      width: calc(100% - 4px);
      margin: 2px;
      position: relative;
      background: $black10;
      border-radius: 5px;
      max-height: 96px;

      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      .image {
        border-radius: 5px;
        width: 100%;
        position: absolute;
        animation: fadeInAnimation ease 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }

    .label {
      font-size: 12px;
      color: $almostBlack;
      width: 100%;
      padding: 0 3px;
      margin-bottom: 5px;
      display: block;
      text-align: center;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
