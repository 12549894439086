@import 'editor/src/comonStyles/variables';

.labelContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 18px 12px;
}

.label {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
}

.hover {
  &:hover {
    background: $rulerBorderColor;
  }
}

.icon {
  display: flex;
  width: 15%;
}

.switch {
  display: flex;
  width: 20%;
}

.textContainer {
  width: 65%;
  position: relative;

  .errorMsg {
    position: absolute;
    color: $red;
    font-size: 10px;
    top: 25px;
  }
}

.textInput {
  width: 100%;
}
