@import 'editor/src/comonStyles/variables';

.productSwitcherItem {
  width: 100%;
  height: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;

  &:not(.disabled, .mobile):hover {
    background-color: $buttonHoverBackground;
    border-color: $buttonHoverBackground;

    &:active {
      background-color: $buttonActiveBackground;
      border-color: $buttonActiveBorder;
    }
  }
}

.productSwitcherItemPreview {
  height: 100%;
  border-radius: 4px;
  margin-right: 12px;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    object-fit: cover;
    width: 48px;
    height: 48px;
  }
}

.productSwitcherItemTitle {
  font-size: 14px;
  color: #383838;
  line-height: 21px;
  letter-spacing: 0.1px;
  font-style: normal;
  font-weight: 500;
}

.productSwitcherSubtitle {
  color: #6B6B6B;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
}

.icon {
  margin-left: auto;
}
