@import 'editor/src/comonStyles/variables';

.adjustment {
  position: relative;

  .label {
    font-size: 12px;
    color: $almostBlack;
    display: flex;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }

  .sliderRow {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.disabled {
      .input {
        opacity: 0.5;
      }

      .slider {
        opacity: 0.5;
      }
    }

    .icon {
      flex-grow: 0;
      width: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slider {
      flex-grow: 1;
      padding: 0 5px;
    }

    .input {
      border: 1px solid $black10;
      border-radius: 3px;
      width: 61px;
      height: 40px;
      padding: 0 5px;
      text-align: center;
    }
  }
}
