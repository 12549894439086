@import 'editor/src/comonStyles/variables';

.Button {
  font-size: 16px;
  line-height: 20px;
  color: black;

  &:not(.disabled):active {
    background: none;
    color: initial;
  }

  &.active {
    border: 1px solid $blue;
    background-color: #eef4ff;
  }
}
