@import 'editor/src/comonStyles/variables';

.positionProperty {
  display: flex;
  flex-direction: row;

  .inputs {
    width: 70%;

    .inputWrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0;

      .input {
        margin-left: 10px;
      }
    }
  }
}
