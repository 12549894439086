@import 'editor/src/comonStyles/index';

.addImagesHint {
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $black50;
  margin-bottom: 5px;

  .addImagesText {
    font-size: 16px;
  }
}
