@import 'editor/src/comonStyles/variables';

.iconCollections {
  svg {
    width: 24px;
    height: 24px;

    > [fill='none'] {
      fill: none !important;
    }
  }
}
