.buttonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.radioContainer {
  width: 24px;
}

.input {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #333;
  border-radius: 50%;
  outline: none;
  position: relative;
  cursor: pointer;

  &.disabled {
    border-color: #bdbdbd;
    cursor: auto;
  }
}

.input:checked {
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #212121;
  }
}

.option {
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 1px;

  &:hover {
    background: #f7f7f7;
  }

  &.disabled {
    cursor: auto;
    background: transparent;
  }
}

.option + .option {
  margin-top: 10px;
}

.content {
  color: #6b6b6b;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  margin-left: 30px;

  &.disabled {
    color: #bdbdbd;
  }
}

.label {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #212121;
  cursor: pointer;

  &.disabled {
    color: #bdbdbd;
    cursor: auto;
  }
}
