@import 'editor/src/comonStyles/variables';
@import 'editor/src/comonStyles/spacers';

.edgeOption {
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;

  &:hover, &.selected {
    border-color: #212121;
    background: $neutral10;
  }
}

.edgeOption + .edgeOption {
  margin-top: $spacer;
}
