@import 'editor/src/comonStyles/variables';

.PersonalisationFlowContent {
  padding: 0 12px 12px;
}

.icon {
  max-height: 24px;
  max-width: 24px;
}
