.wrapper {
  width: 89px;
  position: relative;
  margin-bottom: 8px;

  .preview {
    cursor: pointer;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    height: 89px;
    margin-bottom: 4px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .removeButton {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 28px;
    height: 28px;
    border: 1px solid #BDBDBD;
    border-radius: 24px;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    path {
      fill: #6B6B6B;
      stroke: #6B6B6B;
    }

    display: none;
    opacity: 0.6;

    &:hover {
      border: 1px solid #212121;
      opacity: 1;
    }
  }

  .removeIcon {
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
  }

  &:hover {

    .preview {
      border: 1px solid #212121;
    }

    .removeButton {
      display: flex;
    }
  }

  .title {
    font-family: "Gelato Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}
