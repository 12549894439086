@import 'editor/src/comonStyles/variables';

.CustomToastrComponent {
  color: white;
  font-family: "Gelato Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.info {
  background-color: #58abc3;
}

.success {
  background-color: #29845A;
}

.customToastr {
  min-height: 0 !important;
  border: 0 !important;

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.rrt-title) {
    display: none;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.rrt-text) {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
  }
}

.icon {
  color: #fff;
  margin-right: 8px;

  path {
    fill: #fff;
    stroke: #fff;
  }
}

.actionButton {
  margin-left: auto;
  padding-left: 24px;
}
