@import 'editor/src/comonStyles/variables';

.MockupSizeComponent {
  border: 1px solid $black10;
  border-radius: 6px;
  padding: 16px 20px;
  cursor: pointer;

  &.selected, &:hover {
    background-color: $blue10;
    border: 1px solid $signalBlue;
  }

  .name {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: 4px;
  }

  .size {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
  }
}

.MockupSizeComponent + .MockupSizeComponent {
  margin-top: 8px;
}
