@import 'editor/src/comonStyles/variables';

.selectButton {
  flex-grow: 1;
  border: 1px solid transparent;
  height: 100%;
  margin-right: 3px;

  &:last-child {
    margin-right: 0;
  }

  &.disabled {
    opacity: 0.5;
  }
}
