@import 'editor/src/comonStyles/variables';

.wrapper {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px;

  .chunk {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }
}

.filterTabOptions {
  width: 33.33%;
}
