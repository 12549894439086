@import 'editor/src/comonStyles/variables';

.tabContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .headerBlock {
    border-bottom: 1px solid $black5;
  }
}

.shutterstockCollectionTabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 12px 12px 0;

  .shutterstock-collection-tab {
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    line-height: 20px;
    color: $black5;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    text-align: center;
    position: relative;

    &.active {
      cursor: default;
      color: $primary100;
      border-bottom-color: $primary100;
    }
  }
}
