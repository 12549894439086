@import 'editor/src/comonStyles/variables';

.ButtonMore {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;

  .dropDown {
    flex-direction: column;
    justify-content: left;
    border-radius: 6px;
    top: 40px;
    right: 10px;

    > :global(.button) {
      justify-content: left;
      padding: 0 5px;
      width: 100%;
      white-space: nowrap;

      svg {
        margin-right: 8px;
      }
    }
  }
}

.editIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 24px;
}

.button {
  width: 100%;
  justify-content: flex-start;
  padding-right: 5px;

  svg {
    margin-right: 4px;
  }
}
