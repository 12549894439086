.SaturationSelector {
  display: flex;
  height: 100px;
  width: 100%;
  padding: 10px;
}

.inside {
  border-radius: 3px;
  background-image: linear-gradient(to bottom, transparent, black), linear-gradient(to right, white, transparent);
  position: relative;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.handle {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 100%;
  box-shadow: 0 0 15px #00000026;
  transform: translate(-10px, -10px);
}
