@import 'editor/src/comonStyles/variables';

.priceWrapper {
  padding: 0 12px;
  font-family: 'Gelato Sans', sans-serif;
  text-align: right;

  @media only screen and (max-width: $maxMobileWidth) {
    padding: 0 8px;
  }

  .priceLabel {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.32px;
    color: $neutral80;

    @media only screen and (max-width: $maxMobileWidth) {
      display: none;
    }
  }

  .priceValue {
    display: flex;
    align-items: baseline;
    gap: 2px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.24px;
    word-break: break-all;
    animation: animationOnUpdate 500ms;
    transform-origin: 100% 0;

    .currentValue {
      font-size: 14px;
    }

    .originalValue {
      font-size: 12px;
      text-decoration: line-through;
    }

    .originalValue + .currentValue {
      color: $destructive70;
    }

    @media only screen and (max-width: $maxMobileWidth) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

@keyframes animationOnUpdate {
  0% {
    color: $neutral60;
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    color: $neutral60;
  }
}
