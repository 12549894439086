@import 'editor/src/comonStyles/variables';

.warning {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 12px 16px 18px;
  background: #eef4ff;
  color: #355799;
  border: 1px solid #355799;
  border-radius: 3px;
  margin-top: 12px;
  font-size: 16px;
  line-height: 1.25em;
}

.infoIcon {
  margin-right: 12px;

  circle, path {
    stroke: #355799;
  }
}
