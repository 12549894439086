@import 'editor/src/comonStyles/variables';

.DimensionField {
  display: flex;
  color: $black;
  border: 1px solid rgba($black, 0.2);
  align-items: center;
  border-radius: 3px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  padding-right: 12px;

  &.error {
    border: 1px solid rgba($black, 0.2);
    background-color: $lightRed;
    border-color: $signalRed;
  }

  .icon {
    margin-left: 4px;
    max-width: 32px;
    max-height: 32px;
    flex-shrink: 0;
  }

  input {
    border: none;
    color: inherit;
    background: inherit;
    width: 100%;
    overflow: hidden;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
  }

  input:focus {
    outline: none;
  }

  .meter {
    color: #858585;
    flex-shrink: 0;
  }
}
