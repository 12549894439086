@import 'editor/src/comonStyles/variables';

.eventRowBlock {
  min-height: 26px;
  position: relative;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;

  .eventItemCheckbox {
    width: 100%;
    align-items: flex-start;
    font-size: 14px;

    .eventItemCheckboxLabel {
      display: flex;
      justify-content: space-between;
      text-wrap: wrap;
      padding-left: 8px;
      width: 100%;

      .eventDateLabel {
        text-wrap: nowrap;
        color: $neutral70;
        padding-left: 6px;
      }
    }
  }

  .eventActionBtnsBlock {
    display: flex;
    align-items: center;
    right: 12px;
    height: 19px;
    padding-left: 12px;

    @media (min-width: 500px) {
      position: absolute;
      display: none;
    }

    .editActionButton {
      height: 30px;
      padding: 0 2px;
      background-color: transparent;

      [fill^='#'] {
        fill: $neutral70;
      }
    }

    .deleteActionButton {
      height: 30px;
      padding: 0 2px;
      background-color: transparent;

      [fill^='#'] {
        fill: #E51C00;
      }
    }
  }

  &:not(.disabled):hover {
    background-color: $neutral5;

    .eventActionBtnsBlock {
      display: flex;
      background-color: $neutral5;
    }
  }
}
