@import 'editor/src/comonStyles/index';

.MobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: none;

  &.notShown .backdrop {
    opacity: 0;
    pointer-events: none;
  }

  &.notShown .menu, &.hidden .menu, &.hidden {
    transform: translate3d(0, 100%, 0);
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(2px);
  transition: opacity 150ms linear;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: transform 150ms ease-out;

  &.dragging {
    transition: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    height: 200%;
    width: 100%;
    background: $black10;
  }
}

.menuHandleZone {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 7px;
  height: 50px;
}

.menuHandle {
  width: 50px;
  background: rgba(51, 51, 51, 0.3);
  height: 4px;
  border-radius: 30px;
}

.menuContent {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;

  &.flex {
    display: flex;
    flex-direction: column;
  }
}

.MobileMenuContainer {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
