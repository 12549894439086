@import 'editor/src/comonStyles/variables';

.PersonalisationDropDownBtn {
  height: 42px;
  display: flex;
  align-items: center;
  background: $white;
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 3px;
  padding: 12px;
  cursor: pointer;
  transition: border-color 100ms linear;

  &:hover {
    border-color: $blue;
  }
}

.PersonalisationDropDown {
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
}

.dropdownOption {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background-color: #f4f6f8;
  }

  &.selected {
    background-color: #eef4ff;
  }
}

.image {
  flex-shrink: 0;
  height: 32px;
  width: 32px;
  object-fit: contain;
  margin-right: 5px;
}

.label {
  flex: 1 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}
