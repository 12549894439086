.skeletontBlockTitle {
  min-height: 33px;
  margin-top: 11px;
}

.skeletontBlockContentTitle {
  min-height: 40px;
  margin-bottom: 11px;
}

.skeletontBlockContent {
  min-height: 80px;
  margin-bottom: 11px;
}
