@import 'editor/src/comonStyles/variables';

.fontColorBox {
  cursor: pointer;
  margin: 0 4px 8px;

  @media only screen and (max-width: $maxMobileWidth) {
    .fontColorBox {
      &.mobile {
        margin: 0 7px 12px;
      }
    }
  }

  &:hover {
    .container {
      border-color: #212121;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid rgba($black, 0.3);
}

.circle {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba($black, 0.1);
}

.checkIcon {
  width: 80%;
  stroke-opacity: 0.3;
}

.active {
  background: linear-gradient(0deg, rgba($signalBlue, 0.1), rgba($signalBlue, 0.1)), $white;
  border: 1px solid #212121;
}

.noBorder {
  border: none;
}

.suggested {
  border: 1px solid #212121;
}

.noColorWrapper {
  position: relative;
}

.noColorIcon {
  position: absolute;
  opacity: 0.6;
}
