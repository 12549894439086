@import 'editor/src/comonStyles/variables';

@media only screen and (max-width: $maxMobileWidth) {
  .wrapper {
    &.mobile {
      display: flex;
      flex-direction: column;
      
      & > * {
        padding: 12px 12px 0;
      }
    }
  }
}

.colorInfo {
  font-size: 16px;
  margin-top: 24px;
  color: $neutral90;
}
