@import 'editor/src/comonStyles/variables';

.AbstractLayerItem {
  border-bottom: 1px solid $black5;
  display: flex;
  cursor: pointer;

  &.nonInteractable {
    pointer-events: none;
  }

  &:hover .element {
    background-color: $buttonHoverBackground;
  }

  &.selected .element {
    background-color: $neutral10;
    border-color: #212121;

    &:hover {
      background-color: $buttonActivatedHoverBackground;
    }
  }

  .element {
    display: flex;
    border: 1px solid transparent;
    margin: 4px 0;
    min-height: 48px;
    flex-direction: column;
    border-radius: 6px;
    padding: 5px 12px;
    justify-content: space-between;
    width: 100%;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .badges {
    width: 100%;
    display: flex;
    align-items: center;

    > * {
      margin: 5px 5px 5px 0;
    }
  }

  .left {
    display: flex;
    align-items: center;
    height: 40px;
    flex: 1 0 0;
    overflow: hidden;
  }

  .right {
    height: 100%;
    display: none;
  }

  &:hover .right, &.selected .right, .right.isVisible {
    display: flex;
  }
}

.text {
  flex: 1 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  align-items: center;
  letter-spacing: 0.1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  &.clickable {
    &:hover {
      background-color: $lightGrey;
    }
  }
}


.thumb {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .image {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 100%;

    .redDot {
      align-items: center;
      justify-content: center;
      height: 12px;
      width: 12px;
      position: absolute;
      top: -6px;
      right: -6px;

      svg {
        > * {
          fill: $red;
        }
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.error {
  font-size: 10px;
  line-height: 12px;
  color: $red;
  padding-bottom: 6px;
  margin-left: 45px;
}
