@import 'editor/src/comonStyles/variables';

.addonsList {
  .section {
    background: $black5;
    padding: 10px;
    border-radius: 6px;
    margin-top: 10px;

    .header {
      font-size: 12px;
      font-weight: normal;
      color: $black70;
      margin-top: 0;
    }

    .list {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -3px;
    }
  }
}

.noResults {
  padding: 30px 0;
  text-align: center;
  font-size: 20px;
  font-family: Roboto, Arial, sans-serif;
  color: $black50;
}
