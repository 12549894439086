.Information {
  display: flex;
  align-items: center;
  background: #eef1fb;
  border: 1px solid #5178b4;
  border-radius: 3px;
  padding: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #5178b4;

  svg {
    stroke: #5178b4;
  }
}
