@use 'sass:color';
@import 'editor/src/comonStyles/variables';

div.guidelinesContainer {
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tooltip {
  line-height: 21px;
  margin-top: -25px;
}
