@import 'editor/src/comonStyles/index';

.reuploadImageButtonWrap {
  position: absolute;
  top: 5px;
  left: 5px;
  pointer-events: all;
  z-index: 1002;
  width: 25px;
  height: 25px;

  .reuploadImageButton {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;

    svg {
      width: 14px;
      position: relative;
      left: -1px;
    }
  }
}
