@import 'editor/src/comonStyles/variables';

.option {
  max-width: 100%;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  padding: 4px;
  margin-right: 6px;
  border: 1px solid $black10;
  border-radius: 6px;
  margin-bottom: 6px;
  cursor: pointer;
  align-items: center;

  &.active, &:hover {
    border-color: #212121;
    background: $neutral10;
  }

  &.noPrice {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }

  &.fill {
    width: 100%;
  }

  .color {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $black10;
  }

  .icon {
    margin-right: 6px;
    height: 42px;
    border-radius: 4px;
  }

  .content {
    padding: 2px 0 2px 2px;
    flex: 1 0;
    overflow: hidden;
  }
}

.price {
  font-size: 14px;
  font-weight: 400;
}

.name {
  font-size: 14px;
  font-weight: 400;
}

.description {
  display: flex;
  color: $blue;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.priceRegular {
  color: $black;
  font-weight: bold;
}

.priceInitial {
  color: #8e8e8e;
  margin-right: 5px;
  text-decoration: line-through;
}

.priceDiscounted {
  color: #ed4c4c;
  font-weight: 700;
}
