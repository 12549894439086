@import 'editor/src/comonStyles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and (max-width: $maxMobileWidth) {
    padding: 0 12px;
  }
}

.contentwrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      font-size: 12px;
      font-weight: normal;
      color: $almostBlack;
      margin: 0;
    }
  }
}
