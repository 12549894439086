@import 'editor/src/comonStyles/variables';

.linkButton {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: underline;
  }
}

.black {
  // to avoid overriding it from the outer components as it break styles in many places
  color: $primary100 !important;
}
