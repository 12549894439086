@import 'editor/src/comonStyles/variables';

.tabContainer {
  border: 1px solid $lightGrey;
  border-radius: 6px;
  display: flex;

  .tab {
    display: flex;
    margin: 4px;
    padding: 0 4px;
    height: 32px;
    min-width: 112px;
    flex: 1;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .selected {
    background: $neutral10;
    border: 1px solid #212121;
    border-radius: 6px;
  }
}
