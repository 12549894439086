@font-face {
  font-family: 'Gelato Sans';
  src: url('/fonts/GelatoSans/GelatoSans-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gelato Sans';
  src: url('/fonts/GelatoSans/GelatoSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gelato Sans';
  src: url('/fonts/GelatoSans/GelatoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gelato Sans';
  src: url('/fonts/GelatoSans/GelatoSans-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
