@import 'editor/src/comonStyles/';

.cropZoomDropdown {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 292px;
}

.zoomInIcon {
  path {
    fill: $almostBlack;
  }
}
