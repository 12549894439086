@import 'editor/src/comonStyles/variables';

.edgeContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
  overflow-x: auto;
}
