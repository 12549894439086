@import 'comonStyles/global-helpers';
@import 'comonStyles/fonts';

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;

  * {
    box-sizing: border-box;
  }
}
