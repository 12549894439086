@import 'editor/src/comonStyles/variables';

.axisHorizontal {
  top: 0;
  height: 20px;
  background-color: $rulerBackgroundColor;
  position: absolute;
  width: 100%;
  border: 1px solid $rulerBorderColor;
}

.axisHorizontalSpread {
  position: absolute;
  height: 100%;
  background-color: $rulerOverlayBackgroundColor;
}

.axisVertical {
  top: 0;
  position: absolute;
  height: 100%;
  width: 20px;
  background-color: $rulerBackgroundColor;
  border: 1px solid $rulerBorderColor;
}

.axisVerticalSpread {
  position: absolute;
  width: 100%;
  background-color: $rulerOverlayBackgroundColor;
}

.delimiter {
  position: absolute;
  height: 4px;
  bottom: -5px;
  left: 50%;
  width: 1px;
  background-color: $rulerDelimiterColor;
}

.axisVertical .delimiter {
  transform: translateX(-1px);
}

.delimiterNumber {
  position: absolute;
  font-size: 10px;
  line-height: 12px;
  width: 40px;
  text-align: center;
  color: $rulerDelimiterColor;
  user-select: none;

  &.delimiterNumberHorizontal {
    margin-left: -20px;
    top: 3px;
  }

  &.delimiterNumberVertical {
    left: -10px;
    margin-top: -6px;
    transform: rotate(-90deg);
  }

  &.spreadSize {
    color: #5991ff;
    font-weight: 700;
  }
}

.crossing {
  top: 0;
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: $rulerBackgroundColor;
  border: 1px solid $rulerBorderColor;
}
