.textAlignButton {
  justify-content: center !important;

  .icon svg {
    margin-right: 0 !important;
    stroke: none;
    width: 24px;
    height: 24px;
  }
}

.textAlignButton + .textAlignButton {
  margin-left: 5px;
}
