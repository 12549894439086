@import 'editor/src/comonStyles/index';

.usageBadge {
  background: $white;
  border: 1px solid $blue;
  margin-right: 3px;
  padding: 1px 5px;
  border-radius: 8px;
  height: 14px;
  font-size: 12px;
  line-height: 12px;
  color: $blue;
  pointer-events: all;
  display: flex;
  justify-content: left;

  svg {
    display: inline-block;
    width: 12px;
    height: 12px;
    fill: $blue;
    stroke: $blue;
    margin: 1px 2px 0 0;
  }
}
