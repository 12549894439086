.dropdownContent {
  & > div {
    margin-bottom: 5px;
  }

  :global(svg) {
    max-height: 24px;
    max-width: 24px;
  }
}

.button {
  justify-content: left;
  span { padding: 0 4px; }
}
