@import 'editor/src/comonStyles/variables';

// Settings
$button-size: 48px;

.header {
  grid-area: embedded-header;
  background-color: $white;
  padding: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $black10;

  @media only screen and (max-width: $maxMobileWidth) {
    padding: 4px 12px;
  }
}

.desktopButton {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Gelato Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.24px;
  border-radius: 24px;
}

.mobileButton {
  flex-shrink: 0;
  flex-grow: 1;
}

.rightElementsWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-grow: 0;
  flex-shrink: 1;

  @media only screen and (max-width: $maxMobileWidth) {
    gap: 4px;
  }
}

.leftElementsWrapper {
  flex-grow: 0;
  flex-shrink: 1;
}

.childrenWrapper {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
}
