@import 'editor/src/comonStyles/index';

.actionBar {
  display: none;
  padding: 10px 12px;
  max-height: 100vh;
  border-top: 1px solid #e9e9e9;

  &.active {
    display: block;
  }

  &.fullWidth {
    padding: 10px 0;
  }
}
