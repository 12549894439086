.PreviewCaroussel {
  width: 100%;
}

.mainPreview {
  width: 100%;
  max-height: 600px;
  object-fit: contain;
}

.minatures {
  display: flex;
  overflow-x: auto;
  margin-top: 11px;
}

.miniature {
  background-size: cover;
  width: 80px;
  height: 80px;
  border: 2px solid #f4f4f4;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background-blend-mode: color;
    background-color: hsla(0deg, 0%, 100%, 0.3);
    border-color: #5891ff;
  }

  &.active {
    border-color: #5891ff;
    pointer-events: none;
  }
}

.miniature + .miniature {
  margin-left: 11px;
}
