@import 'editor/src/comonStyles/variables';

.categoryName {
  color: $black;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  margin: 8px 0;
}
