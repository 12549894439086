@import 'editor/src/comonStyles/variables';

.addLayerButtonWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  overflow: visible;

  .button {
    border: 1px solid $neutral30;
    margin-left: 4px;
    margin-right: 15px;
    white-space: nowrap;
  }

  .dropDown {
    position: absolute;
    top: 50px;
    right: 15px;
  }
}

.icon {
  margin-right: 4px;
}
