@import 'editor/src/comonStyles/variables';

.label {
  font-size: 10px;
  margin: 12px 0;
  color: #707070;
  font-weight: 400;
}

.labelCentered {
  font-size: 10px;
  margin: 6px 0;
  color: #707070;
  font-weight: 400;
  text-align: center;
}

.picker {
  display: flex;
  flex-direction: column;
}

.slider {
  margin-top: 14px;
}

.input {
  margin: 20px 12px 0;
  outline: none;
  padding: 10px;
  text-align: center;
  border: 1px solid rgba($almostBlack, 0.2);
  color: rgba($almostBlack, 0.6);
  border-radius: 3px;
  font-weight: 400;
  text-transform: uppercase;
}
