@import 'editor/src/comonStyles/variables';

.loaderContainer {
  position: absolute;
  top: 0;
  z-index: 1;
  transform-origin: left top;
  pointer-events: none;
  user-select: none;
}

.loader {
  position: relative;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 4px 8px;
  display: flex;
  color: white;
  font-size: 10px;
  min-width: 80px;
  pointer-events: none;
  transform: translateX(-50%);
}

.spinner {
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1s linear infinite;
  z-index: 2;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
