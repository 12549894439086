@import 'editor/src/comonStyles/variables';

.label {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  user-select: none;
  font-weight: bold;
  padding: 8px;
}
