@import 'editor/src/comonStyles/variables';

.publicHolidaysContentBlock {
  flex-grow: 1;
  flex-shrink: 0;
  // we should specify this property separately. If we write it like `flex: 1 0 0` webpack production
  // builder will compile it to `flex: 1 0` and `overflow-y: auto` will be ignored without flex-basis
  flex-basis: 0;
  overflow-y: auto;
  margin: 0 -12px;
  padding: 0 12px;
}

.calendarEventsContentBlock {
  padding: 12px 0;

  .eventItemCheckboxLabel {
    display: flex;
    justify-content: space-between;
    text-wrap: wrap;
    min-height: 26px;

    span {
      text-wrap: nowrap;
    }
  }
}

.autoApplyPublicHolidaysBlock {
  display: flex;
  gap: 12px;
  line-height: 20px;
  font-size: 14px;
  font-family: "Gelato Sans";
  padding: 16px 0 12px 12px;
}

.autoApplyPublicHolidaysBlockDisabled {
  color: $neutral50;
}

.selectAllBlock {
  padding: 12px 12px 18px;
  margin-bottom: 6px;
  border-bottom: 1px solid $black10;
  width: 100%;

  .selectAllLabel {
    font-size: 14px;
  }
}
