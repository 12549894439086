@import 'editor/src/comonStyles/variables';

.ButtonUndoRedo {
  display: flex;

  &.icon, &.label {
    align-items: center;
  }

  &.labelIcon {
    flex-direction: column;
  }
}
