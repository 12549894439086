@use "sass:math";

// Width and height
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

//== Spacer
$spacer: 6px;
$spacer-x: $spacer;
$spacer-y: $spacer;

$spacers: (
        0: (
                x: 0,
                y: 0
        ),
        '1q': (
                x: math.div($spacer-x, 4),
                y: math.div($spacer-y, 4)
        ),
        'half': (
                x: math.div($spacer-x, 2),
                y: math.div($spacer-y, 2)
        ),
        '3q': (
                x: $spacer-x*.75,
                y: $spacer-y*.75
        ),
        1: (
                x: $spacer-x,
                y: $spacer-y
        ),
        'onehalf': (
                x: ($spacer-x * 1.5),
                y: ($spacer-y * 1.5)
        ),
        2: (
                x: ($spacer-x * 2),
                y: ($spacer-y * 2)
        ),
        3: (
                x: ($spacer-x * 3),
                y: ($spacer-y * 3)
        ),
        4: (
                x: ($spacer-x * 4),
                y: ($spacer-y * 4)
        ),
        5: (
                x: ($spacer-x * 5),
                y: ($spacer-y * 5)
        ),
        6: (
                x: ($spacer-x * 6),
                y: ($spacer-y * 6)
        )
);


// Spacers static
// my - vertical space
// mx - horizontal
// mt, mr, mb, ml with a number of space like 'mr-2 mb-4 mt-onehalf'
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x: map-get($lengths, x);
    $length-y: map-get($lengths, y);

    .#{$abbrev}-#{$size} {
      #{$prop}: $length-y $length-x;
    }
    // a = All sides
    .#{$abbrev}t-#{$size} {
      #{$prop}-top: $length-y;
    }
    .#{$abbrev}r-#{$size} {
      #{$prop}-right: $length-x;
    }
    .#{$abbrev}b-#{$size} {
      #{$prop}-bottom: $length-y;
    }
    .#{$abbrev}l-#{$size} {
      #{$prop}-left: $length-x;
    }

    // Axes
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length-x;
      #{$prop}-left: $length-x;
    }
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length-y;
      #{$prop}-bottom: $length-y;
    }
  }
}
