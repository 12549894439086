@import 'editor/src/comonStyles/index';

.MaxMessageInfo {
  background-color: $blue10;
  box-shadow: inset 0 0 0 1px $signalBlue, 0 0 0 0 transparent;
  color: $signalBlue;
  margin: 1em 0;
  padding: 1em 1.5em;
  line-height: 1.25rem;
  border-radius: 0.25rem;
  font-size: 14px;
}
