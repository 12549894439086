@import 'editor/src/comonStyles/variables';

.iconContainer {
    height: 32px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #C0E1FF;

    .icon {
        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.bannerContent {
    color: $primary100;
  
    .bannerLink {
      color: $info70;
    }
  }