@import 'editor/src/comonStyles/variables';

.ConditionGroupBuilder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .list {
    flex: 1 0 0;
  }

  .prompt {
    margin: 12px 12px 0;
  }
}

.addCondition {
  margin: 12px;
  display: flex;
  flex-shrink: 0;
}

.empty {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  flex-direction: column;
}

.emptyText {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-top: 24px;
}
