@import 'editor/src/comonStyles/variables';

.confirmationMenu {
  font-family: 'Gelato Sans', sans-serif;
  padding: 24px;

  .closeIconWrapper {
    display: flex;

    .closeIcon {
      cursor: pointer;
      margin-left: auto;
    }
  }

  .menuHeading {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    max-width: 90%;
  }

  .menuButtonsWrapper {
    display: grid;
    gap: 12px;
    padding-top: 12px;
    justify-items: left;

    .menuButton {
      display: flex;
      gap: 8px;
      border-radius: 24px;

      .buttonIcon {
        min-width: 26px;
      }
    }

    .dangerButton {
      color: $destructive70;

      svg {
        > * {
          stroke: $destructive70;
          fill: $destructive70;
        }
      }
    }
  }
}
