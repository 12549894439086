@import 'editor/src/comonStyles/variables';

.addLayerButtonWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  overflow: visible;
  width: auto;
  min-width: 100%;

  svg {
    margin-right: 4px;
  }

  .dropDown {
    position: absolute;
    right: -5px;
    left: 0;
  }

  .addLayerButton {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 5px 0;
    min-width: 100%;
  }
}
