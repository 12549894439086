@import 'editor/src/comonStyles/variables';

.title {
  color: $black;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-top: 12px;
  margin-bottom: 8px;
}
