@import 'editor/src/comonStyles/variables';

.IndicatorBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 11px;
  white-space: nowrap;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;

  svg {
    width: auto;
    max-height: 60%;
  }

  &.Medium {
    background-color: $signalOrange;

    svg {
      fill: white;
    }
  }

  &.High {
    background-color: $destructive;

    svg {
      fill: white;
      margin: 0 4px;
    }
  }

  &.badge.High svg {
    margin: 0;
  }

  &.hasText svg {
    margin: 0;
  }

  &.Low {
    background-color: $info70;

    svg {
      stroke: white;
      fill: white;
      height: 18px;
      width: 18px;

      path {
        fill: white;
      }
    }
  }

  &.Check {
    background-color: $success;

    svg {
      stroke: white;
      fill: white;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.hasText {
    padding: 3px 8px;

    .icon { margin-right: 4px; }
  }
}
