@import 'editor/src/comonStyles/index';

.the3dPreview {
  width: 100%;
  height: 100%;
  grid-area: preview;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
