.Sections {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 0;
  overflow: hidden;
}

.Section {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: transform 300ms ease-out;

  .arrow {
    transition: transform 100ms ease-out;
    transform: rotate(-90deg);
  }

  &.active .arrow, &:hover .arrow {
    transform: rotate(0deg);
  }
}

.Section + .Section {
  .header {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.content {
  flex: 1 0 0;
  overflow-y: auto;
}
