@import 'editor/src/comonStyles/variables';

.buttonContainer {
  position: relative;
}

.sortButton {
  color: $blue;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.dropdownContent {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 24px;
  width: 140px;
  background-color: white;
  border-radius: 6px;

  & > div {
    &:hover {
      color: $blue;
      cursor: pointer;
    }
  }
}
