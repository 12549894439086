@import 'editor/src/comonStyles/variables';

.headerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.TabContentHeader {
  padding: 10px 11px 13px;
  margin: 0 -11px;
  background: #fff;
  display: flex;
  align-items: center;

  .header {
    font-size: 21px;
  }

  > div {
    margin-right: 16px;
  }

  .close {
    margin-right: 0;
    flex: 1 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
