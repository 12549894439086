// prevent scroll on desktop when draggable item next to the screen edges
:global(body.dragging) {
  overflow: hidden;
}

:global(.draggableClone) {
  transform: translate(-50%, -50%);
  margin: 0;
  position: absolute;
  opacity: 0.5;
  z-index: 1500;
  pointer-events: none;
  touch-action: none;
  user-select: none;
}
