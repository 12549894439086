@import 'editor/src/comonStyles/index';

$scroll-width: 10px;
$scroll-color: rgba(170, 170, 170, 0.8);

.scrollbarContainer {
  position: absolute;
  border-radius: $scroll-width;

  &.horizontal {
    bottom: 0;
    width: calc(100% - #{$scroll-width});
    height: $scroll-width;
  }

  &.vertical {
    top: 0;
    right: 0;
    width: $scroll-width;
    height: calc(100% - #{$scroll-width});
  }
}

.scrollbarControl {
  height: 100%;
  border-radius: 10px;
  background-color: $scroll-color;
  cursor: pointer;
  position: relative;

  &.horizontal {
    height: 100%;
    width: 100px;
  }

  &.vertical {
    width: 100%;
    height: 100px;
  }
}
