@import 'editor/src/comonStyles/variables';

.sizeGuide {
  color: $signalBlue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
