@import 'editor/src/comonStyles/variables';

.abstractPopup {
  font-family: 'Gelato Sans', sans-serif;
  position: fixed;
  width: 600px;
  max-width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: $white;
  border: 1px solid $white;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.2), 0 9px 46px 0 rgba(0, 0, 0, 0.12), 0 24px 38px 0 rgba(0, 0, 0, 0.14);

  .header {
    border-bottom: 1px solid $black10;
    padding-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: bold;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.32px;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .content {
    padding: 20px 0;
    border-bottom: 1px solid $black10;
    color: $almostBlack;
  }

  .buttons {
    padding: 20px 0 0;
    display: flex;
    justify-content: flex-end;

    > * {
      margin-left: 10px;
      border-radius: 24px;

      @media only screen and (max-width: $maxMobileWidth) {
        margin-left: 0;
      }
    }
  }
}

.fadeMask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  z-index: 998;
}

.AbstractPopupContainer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
}
