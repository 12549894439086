.mainMenuContent {
  display: grid;
  width: 100%;

  .tabElements {
    display: flex;
    
    > * {
      margin: 4px;
    }
  }
}
