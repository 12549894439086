@import 'editor/src/comonStyles/variables';

.TopMenuDesktop {
  position: relative;
  background-color: $white;
  width: 100%;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
  grid-area: topMenu;
  border-bottom: 1px solid #e9e9e9;

  :global(svg) {
    max-height: 24px;
    max-width: 24px;
  }
}

.item {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
  opacity: 0;
}

.placeholder {
  flex: 1 1 0;
}
