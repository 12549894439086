@import 'editor/src/comonStyles/variables';

.confirmationText {
  font-size: 1em;
  margin: 0;
  font-weight: 300;
}

.confirmationButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  :global(.button) {
    margin-right: 12px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  button {
    margin-left: 16px;
    border-radius: 24px;
  }
}

@media only screen and (max-width: $maxMobileWidth) {
  .confirmationText {
    text-align: center;
  }

  .confirmationButtons {
    flex-direction: column-reverse;
    align-items: stretch;
    gap: 8px;
    margin-left: 0;

    button {
      margin-left: 0;
    }
  }
}
