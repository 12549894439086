.Overlay {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  min-height: 100px;
  background-color: white;
  box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.2);

  &.hidden {
    opacity: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    padding: 12px;
  }

  .close {
    margin-left: 10px;
    cursor: pointer;
  }
}

.OverlayContainer {
  position: absolute;
  left: 645px;
  top: 0;
  z-index: 99;
}
