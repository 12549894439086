@import 'editor/src/comonStyles/variables';

.ProductSizeControl {
  .label {
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $black;
    font-size: 12px;
  }

  .addSize {
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    color: $black;
    padding: 4px 12px;
    border-radius: 24px;

    &:hover {
      background-color: $black10;
      background-color: rgba(33, 33, 33, 0.10);
    }
  }
}
