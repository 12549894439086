.SwitchControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  color: #212121;
  flex: 1 0 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.switch {
  margin-left: 6px;
}
