@import 'editor/src/comonStyles/variables';

.ElementsList {
  overflow-y: auto;
  height: 100%;
  padding: 0 11px;

  &.loadingEmpty {
    overflow: hidden;
  }

  .elementGroupImage {
    height: 100px;
    padding-right: 5px;

    img {
      object-fit: cover;
    }
  }
}

.groupItemContainer {
  li:last-child {
    .elementGroupImage {
      margin-right: 0;
    }
  }
}

.totalCount {
  font-size: 12px;
  line-height: 16px;
  color: $almostBlack;
}

.noResults {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;

  svg {
    width: 30px;
    height: 30px;
  }
}
