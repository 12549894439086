@import 'editor/src/comonStyles/index';

.imageButtonWrap {
  position: absolute;
  top: 5px;
  right: 5px;
  pointer-events: all;
  z-index: 1002;
  border-radius: 8px;

  .imageButton {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    border: none;
    background-color: $neutral10;

    &.hidden {
      display: none;
    }

    &:hover {
      border: 1px solid $neutral60;
    }

    svg {
      width: 12px;
      height: 12px;
      overflow: visible;

      path {
        stroke-width: 1px;
      }
    }
  }
}

.dropDown {
  position: relative;
  top: 0;
  margin-left: 10px;
}

:global .draggableClone {
  :local .imageButtonWrap {
    display: none;
  }
}
