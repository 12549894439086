.UploaderDropZone {
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.arrow {
  transform: rotate(-90deg);
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
