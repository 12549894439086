@import 'editor/src/comonStyles/variables';

.calendarEventsContentBlock {
  flex-grow: 1;
  flex-shrink: 0;
  // we should specify this property separately. If we write it like `flex: 1 0 0` webpack production
  // builder will compile it to `flex: 1 0` and `overflow-y: auto` will be ignored without flex-basis
  flex-basis: 0;
  margin: 0 -12px;
  padding: 0 12px;
  overflow-y: auto;

  .addNewEventBtn {
    margin: 12px 0;
    font-size: 14px;
  }

  .allowPersonalizationBlock {
    display: flex;
    gap: 12px;
    line-height: 20px;
    font-size: 14px;
    font-family: "Gelato Sans";
    padding: 12px;

    .allowPersonalizationSubTitle {
      color: $neutral70;
    }
  }

  .allowPersonalizationBlockDisabled {
    color: $neutral50;

    .allowPersonalizationSubTitle {
      color: $neutral50;
    }
  }

  .selectAllBlock {
    padding: 12px 12px 18px;
    margin-bottom: 6px;
    border-bottom: 1px solid $black10;
    width: 100%;

    .selectAllLabel {
      font-size: 14px;
    }
  }
}
