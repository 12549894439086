@import 'editor/src/comonStyles/variables';

.ButtonElement {
  flex: 1 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  color: $neutral90;
  border-radius: 3px;
  height: 28px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    background-color: $neutral10;
    border-color: $buttonHoverBackground;
  }

  &.selected {
    background-color: $neutral10;
    color: $neutral90;
    pointer-events: none;
    border: 1px solid #212121;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: $black10;
  }
}
