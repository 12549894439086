@import 'editor/src/comonStyles/variables';

.moreButton {
  display: inline-flex;
  position: relative;
  overflow: visible;
  width: auto;
  min-width: 40px;

  .dropDown {
    flex-direction: column;
    justify-content: left;
    border-radius: 6px;
    position: absolute;
    top: 40px;
    right: 0;

    > :global(.button) {
      justify-content: left;
      padding: 0 5px;
      width: 100%;
      white-space: nowrap;
    }
  }
}

.editIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 24px;
}

.button {
  width: 100%;
  justify-content: flex-start;
  padding-right: 5px;

  svg {
    margin-right: 4px;
  }
}

.controlButton {
  height: 100%;

  &:not(.disabled, .mobile):hover {
    background-color: $layerButtonHoverBackground;
    border-color: $layerButtonHoverBorder;
  }
}
