@import 'editor/src/comonStyles/variables';

.LayoutsMobileMenu {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layoutsContainer {
  overflow-y: auto;
}