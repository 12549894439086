@import 'editor/src/comonStyles/variables';

.addEditEventForm {
  padding: 12px 12px 0;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.closeEditMode {
  justify-content: space-between;
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;

  h4 {
    margin: 0;
  }
}

.addEditEventFormActionBtns {
  display: flex;
  gap: 8px;
  padding-bottom: 20px;

  button {
    font-size: 14px;
  }
}

.closeEditFormBtn {
  height:22px;
}

.error {
  flex: 1 0 0;
  grid-column: 1;
  grid-row: 2;
  font-size: 10px;
  line-height: 12px;
  min-height: 12px;
  color: $red;
  display: block;
}

