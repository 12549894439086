@import 'editor/src/comonStyles/variables';

.WarningItemContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &.selected, &:hover {
    &::before {
      content: '';
      background-color: #f2f2f2;
      position: absolute;
      top: 6px;
      bottom: 6px;
      left: 0;
      right: 0;
      border-radius: 6px;
    }
  }
}

.WarningItemContainer + .WarningItemContainer {
  border-top: 1px solid $black5;
}

.InlineActionButton {
  display: inline;
  text-decoration: underline;
}

.actionLinkButton {
  color: $info70;
  margin-top: 8px;
}