@import 'editor/src/comonStyles/variables';

.shutterstockFee {
  padding: 8px 12px;
  margin: 0 -12px;
  background: $white;
  color: $neutral90;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  display: flex;
  height: 37px;

  svg {
    height: 18px;
    padding-right: 6px;
  }
}

.termsOfService {
  padding: 8px 12px;
  margin: 0 -12px;
  border-top: 1px solid $black5;
  background: $white;
  color: $neutral90;
  font-size: 12px;
  line-height: 14px;
}