@import 'editor/src/comonStyles/variables';

.MobilePersonalizeLayersContent {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    flex: 1 0 0;
    overflow-y: auto;
  }
}