.container {
    display: flex;
    align-items: center;;
}

.button {
    padding: 48px;

    &:hover {
        transform: scale(1.4);
    }

    &.nonClickable {
        cursor: not-allowed;
        pointer-events: none;

        opacity: 0.4;
    }
}

.name {
    display: flex;
    justify-content: center;
    font-family: "Gelato Sans", sans-serif;
    width: 115px;
}