.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
  
  .image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; 
    object-fit: contain;
    transition: transform 0.3s ease-out;
    transform-origin: center center;
    user-select: none;
    -webkit-user-drag: none;
    touch-action: pan-x pan-y;
  
    &.zoomed {
      cursor: zoom-out;
    }
  
    &:not(.zoomed) {
      cursor: zoom-in;
    }
  }
  