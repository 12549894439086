@import 'editor/src/comonStyles/variables';

.elementImage {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  touch-action: pan-y;

  .actionIcon {
    display: none;
  }

  .premium {
    position: absolute;
    bottom: 7px;
    left: 5px;
    height: 18px;
    margin-top: 3px;
    display: none;

    svg {
      height: 100%;
    }
  }

  &:hover {
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 2;
    }

    .actionIcon {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;

      svg {
        fill: $almostBlack;
      }
    }
  }

  .image {
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: fill;
  }

  &:hover .premium {
    display: initial;
  }
}

:global(.draggableClone) {
  pointer-events: none;

  &.elementImage {
    width: 150px;
    height: auto;

    img {
      width: 100%;
      height: auto;
      max-height: none;
    }
  }
}
