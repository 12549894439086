@import 'editor/src/comonStyles/variables';

.leftSectionMobile {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;

  > :global(.button) {
    margin: 4px;

    span {
      padding: 0 4px;
    }
  }
}
