@import 'editor/src/comonStyles/variables';

.buttonOpenUploader {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 5px 0;
  min-width: 100%;
  border-radius: 24px;

  .icon {
    margin: 0 10px 0 0;
  }
}
