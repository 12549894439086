@import 'editor/src/comonStyles/variables';

.MenuContent {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 14px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: $almostBlack;
}

.content {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
}

.actionButtonMobile {
  width: 100%;
  margin: 12px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 6px;
  font-weight: 500 !important;
}
