.direction {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  border: 1px solid #e3e5e6;
  border-radius: 4px;
  padding: 4px 6px;
}

.spacing, .mirror {
  margin-bottom: 16px;
}

.scaling {
  margin-bottom: 8px;
}
