@import 'editor/src/comonStyles/index';

.ButtonControl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  border: 1px solid $black30;
  border-radius: 4px;
  padding: 4px 6px;
}
