@import 'editor/src/comonStyles/variables';

.LayerList {
  display: flex;
  flex-direction: column;
}

.subtitle {
  font-weight: 700;
  font-size: 16px;
  color: $black;
  margin: 24px 0;
}

.hint {
  font-weight: 400;
  font-size: 12px;
  color: $neutral90;
  margin: 24px 0;
}
