@import 'editor/src/comonStyles/variables';

.spinnerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  color: #fff;
  text-align: center;
}

.spinnerMain {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba($blue, 0.2);
  border-right: 0.5em solid rgba($blue, 0.2);
  border-bottom: 0.5em solid rgba($blue, 0.2);
  border-left: 0.5em solid $blue;
  transform: translateZ(0);
  animation: load8 0.8s infinite linear;
  margin: 20% auto 10px;
  background: none;
  top: auto;
  left: auto;
  min-width: auto;
  padding: 0;
  display: block;
}

.spinner,.spinner::after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
