@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.filterBlock {
  display: flex;
  padding: 12px 0;
  column-gap: 10px;
}

.filterButton {
  padding: 8px;
  height: 38px;
}

.filterModal {
  position: absolute;
  background-color: $white;
  padding: 0 11px;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  .closeBtn {
    > div {
      margin: 0;
    }
  }

  .filterTitle {
    color: $black;
    margin: 12px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }

  .headerBlock {
    justify-content: end;
  }
}

.title {
  font-size: 21px;
}

.back {
  align-self: stretch;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: color.mix($white, $primary100, 95%);
  }
}