@use 'sass:color';

$blue: #5991FF;
$pink: #E5468C;
$white: #FFFFFF;
$black: #212121;
$red: #F04A47;
$almostBlack: #212121;
$black300: #D6D6D6;
$black333: #333333;

$destructive: #D0412D;
$destructive70: #D0412D;
$destructive90: #671600;

$success: #189A7B;
$success70: #189A7B;

$orange: #AD5C00;
$lightOrange: #FFF5E6;
$lightGreen: #f0fff0;

$signalRed: #FD5F5C;
$lightRed: #FFF3F3;
$signalOrange: #D48C06;
$signalGreen: #249a62;
$signalBlue: #5991FF;
$lightBlue: #5991FF1A;
$lightGrey: #E6E6E6;
$grey: #BDBDBD;
$lighterBlue: #EEF1FB;

$ube: #9C77AC;

$neutral5: #F7F7F7;
$neutral10: #F2F2F2;
$neutral20: #DFDFDF;
$neutral30: #D4D4D4;
$neutral40: #BDBDBD;
$neutral50: #999999;
$neutral60: #8A8A8A;
$neutral65: #8B8B8B;
$neutral70: #6B6B6B;
$neutral80: #525252;
$neutral90: #383838;
$primary100: #212121;
$info50: #6395DF;
$info70: #007CB4;

$blue10: color.mix($white, $blue, 90%);
$orange200: #FFE1A7;

$black5: color.mix($white, $black, 95%);
$black10: color.mix($white, $black, 90%);
$black30: color.mix($white, $black, 70%);
$black50: color.mix($white, $black, 50%);
$black70: color.mix($white, $black, 30%);
$black90: color.mix($white, $black, 10%);
$blue10black5: color.mix($blue10, $black5);
$blue10black10: color.mix($blue10, $black10);

$workspaceBackground: $black5;
$sidebarBackground: $black5;
$tabBackground: $white;

$buttonHoverBackground: $black5;
$buttonHoverBorder: $black5;

$layerButtonHoverBackground: $black30;
$layerButtonHoverBorder: $black30;

$buttonActiveBackground: $black10;
$buttonActiveBorder: $black10;

$buttonActivatedBackground: $blue10;
$buttonActivatedBorder: $blue;

$buttonActivatedHoverBackground: $blue10black5;
$buttonActivatedHoverBorder: $blue;

$buttonActivatedActiveBackground: $blue10black10;
$buttonActivatedActiveBorder: $blue;

$rulerBackgroundColor: #FBFBFB;
$rulerBorderColor: #E6E6E6;

$rulerOverlayBackgroundColor: rgba(89, 145, 255, 0.3);
$rulerDelimiterColor: rgba(51, 51, 51, 0.5);

$maxMobileWidth: 960px;
$mobileTopBarHeight: 48px;

// z-indexes
$backdropZIndex: 1500;
$onTopOfBackdropZIndex: 1600;

//.row-flex variables
$flex-columns: 12;
$flex-row-space: 12px;
$flex-col-space: 12px;