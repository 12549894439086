@import 'editor/src/comonStyles/variables';

.DisplaySection {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.label {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.option {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  &:hover, &.active {
    background-color: #f4f6f8;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.optionLabel {
  margin-left: 12px;
  flex: 1 0 0;
}

.labelOptions {
  margin-top: 12px;
}

.dropdownBtn {
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 3px;

  &:hover {
    background-color: transparent;
    border-color: $blue;
  }
}
