@import 'editor/src/comonStyles/variables';

.table {
  width: 100%;

  tr {
    &:last-child {
      th, td {
        border-bottom: none;
      }
    }

    th {
      text-align: left;
      border-bottom: 1px solid $black5;
      padding: 7px 0;
    }

    td {
      padding: 7px 0;
      text-align: center;
      border-bottom: 1px solid $black5;
    }
  }
}
