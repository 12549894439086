@import 'editor/src/comonStyles/variables';

.MockupListEntry {
  height: 72px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  border: 1px solid #f2f2f2;
  border-radius: 6px;

  &:hover {
    border-color: $signalBlue;
  }

  .image {
    width: 40px;
    height: 100%;
    margin-right: 15px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .icon {
    margin-right: 15px;
  }

  .title {
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .loader {
    margin-left: 5px;
  }
}

.MockupListEntry + .MockupListEntry {
  margin-top: 6px;
}
