@import 'editor/src/comonStyles/variables';

.categoryHeader {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.sceneCategory {
  cursor: pointer;
  padding: 12px 0;
  width: 49%;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  border-bottom: 1px solid $lightGrey;

  &.selected {
    border-bottom: 1px solid $blue;
    font-weight: 500;
  }
}

.scenes {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.sceneItemContainer {
  width: 33%;
}

.sceneItemImageContainer {
  position: relative;
  width: 90px;
  height: 100px;
  cursor: pointer;
  margin: 2px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid $lightGrey;
  text-align: center;
  display: flex;
  justify-content: center; /* Horizontally center the image */
  align-items: center; /* Vertically center the image */

  &:hover {
    opacity: 0.8;
  }

  &.selected {
    border-color: $blue;
    border-radius: 4px;
  }
}

.SceneItem {
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.iconPremium {
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.loader {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  overflow: hidden;

  svg {
    height: 30px;
    width: 30px;
  }
}
