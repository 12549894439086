.backButton {
  cursor: pointer;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  padding: 14px 0;

  &:hover {
    text-decoration: underline;
  }
}

.arrow {
  margin-left: 12px;
  margin-right: 23px;
}

.content {
  padding-top: 12px;
}
