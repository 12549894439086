@import 'editor/src/comonStyles/index';

.buttonReuploadAll {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  .icon {
    margin: 0 0 0 10px;
  }
}
