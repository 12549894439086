.wrapper {
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.colorContainer {
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
}

.colorItem {
  margin: 0 0 5px 0;
}
