@import 'editor/src/comonStyles/index';

.EditorArea {
  overflow: hidden;
  position: relative;
  background-color: $workspaceBackground;
  grid-area: editorArea;
  display: flex;
  flex-direction: column;
}

.canvasContainer {
  width: 100%;
  height: 100%;
  transition: opacity 200ms linear;
  position: relative;
  overflow: hidden;
  flex: 1 0 0;

  &.hidden {
    opacity: 0;
  }
}

:global(.dragViewportMode) {
  cursor: grab;

  &:global(.dragging) {
    cursor: grabbing;
  }

  /* stylelint-disable selector-class-pattern */
  :global(.upper-canvas) {
    pointer-events: none;
  }
}
