.description {
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  margin-bottom: 24px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .option {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background: white;
    cursor: pointer;
    border: none;
    position: relative;
    border-radius: 8px;

    // Use pseudo-element for border to prevent layout shift
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border: 1px solid #BDBDBD;
      border-radius: 8px;
      pointer-events: none;
      transition: border-color 0.2s ease;
    }

    &:hover::before {
      border-color: #757575;
    }

    &.selected::before {
      border: 2px solid #212121;
    }

    img {
      margin-bottom: 16px;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #212121;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: #757575;
      text-align: center;
      margin: 0;
    }
  }
}
