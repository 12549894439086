@import 'editor/src/comonStyles/variables';

.PersonalisationCollection {
  display: grid;
  background: $neutral5;
  border: 1px solid $lightGrey;
  border-radius: 6px;

  .label {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .assetElements {
    border-top: 1px solid $lightGrey;
    padding: 8px;

    &.isBuilding {
      margin: 12px;
    }
  }

  &:hover .menuBtn {
    opacity: 1;
  }

  &.building {
    cursor: grab;

    &.active {
      border-color: #212121;
      background-color: $neutral10;
    }
  }
}

.menuBtn {
  opacity: 0;
}

.PersonalisationCollectionThumbnails, .PersonalisationCollectionColors {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.colorTile {
  border-radius: 3px;
  border: 2px solid transparent;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.48), 0 0 4px rgba(0, 0, 0, 0.12);
  width: 56px;
  height: 56px;
  margin: 2px;
  cursor: pointer;

  &.active {
    border-color: #212121;
  }

  &:hover {
    border-color: #212121;
  }
}
