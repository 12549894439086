.button {
  display: flex;
  align-items: center;
  position: relative;

  .iconContainer {
    position: relative;
    
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      padding: 20px 8px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      flex-shrink: 0;
    }

    .iconPremiumBox {
      position: absolute;
      right: -6px;
      top: -2px;

      .iconPremium {
        width: 20px;
      }
    }
  
  }
  
    .label {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;
      flex: 1 0 0;
    }
  
    &.label-icon .label, .icon {
      margin-right: 5px;
    }
  
    &.label .label {
      text-align: center;
      padding: 0 5px;
    }

    &.label-icon-vertical {
      flex-direction: column;

      .label {
        font-family: Roboto, 'sans-serif';
        font-size: 11px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  
}

