@import 'editor/src/comonStyles/variables';

.PersonalizationSimpleImage {
  background: $neutral5;
  border: 1px solid $lightGrey;
  border-radius: 6px;

  &.building {
    cursor: grab;

    &.active {
      border-color: #212121;
      background-color: $neutral10;
    }
  }

  &:hover .menuBtn {
    opacity: 1;
  }
}

.menuBtn {
  opacity: 0;
}

.imageContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-top: 1px solid $lightGrey;
  padding: 8px;
  gap: 8px;

  &.isBuilding {
    margin: 12px;
  }
}

.imgPreviewStyle {
  width: 40px;
  height: 40px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.48), 0 0 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border: 2px solid $rulerBorderColor;
  object-fit: contain;
}

.uploadBtn {
  color: $black;
  border: 1px solid transparent;
  margin-left: 5px;
  font-size: 14px;
}
