@import 'editor/src/comonStyles/variables';

.menuItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    margin-bottom: 0;
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  cursor: pointer;
  border-radius: 6px;
}

.wrapper {
  bottom: 0;
  right: 10%;
  position: absolute;
}
