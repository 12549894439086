@import 'editor/src/comonStyles/variables';

.DesktopLayout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template:
    'embedded-header embedded-header embedded-header' auto
    'sidebar topMenu rightbar' auto
    'sidebar  editorArea rightbar' 1fr
    / auto 1fr auto;
}

.rightBar {
  width: 310px;
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  flex-shrink: 0;
  background-color: white;
  grid-area: rightbar;
}

.personalisationSidebar {
  padding: 0 12px 12px;
}
