@import 'editor/src/comonStyles/variables';

.widget {
  border-radius: 4px;
  overflow: hidden;
  width: auto;
  margin: 0 0 10px;

  .header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-weight: bold;
    line-height: 20px;
    font-size: 15px;
    cursor: pointer;
    margin: 0;
    padding: 10px 10px 0;
  }

  .content {
    max-height: 0;
    transition: max-height 0.2s ease-in-out;

    &.open {
      max-height: 5000px;
    }
  }

  .contentBlock {
    padding: 10px;
  }

  .widgetHeader {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-weight: bold;
    line-height: 20px;
    font-size: 15px;
    cursor: pointer;
    margin: 0;
    padding: 10px 10px 0;
  }
}
