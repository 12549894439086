.dropdown {
  display: block !important;
  min-width: 250px;

  .alignments {
    display: flex;
  }

  .half {
    flex-direction: column;
    display: inline-flex;
    flex: 1 0 0;
  }

  .half + .half {
    margin-left: 5px;
  }

  .title {
    color: #333;
    margin: 12px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }
}

.button span { padding: 0 4px; }
