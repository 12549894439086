@import 'editor/src/comonStyles/index';

.labelBadge {
  background: $white;
  border: 1px solid $blue;
  margin-right: 3px;
  padding: 1px 5px;
  border-radius: 8px;
  height: 14px;
  font-size: 10px;
  line-height: 12px;
  color: $blue;
  pointer-events: all;
  display: flex;
  justify-content: left;
}
