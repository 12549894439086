.OptionsSection {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.slide .subSection {
    transform: translate3d(-100%, 0, 0);
  }
}

.options {
  flex: 1 0 0;
  overflow-y: auto;
}

.subSection {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 12px;
  transition: transform 300ms ease-out;
}

.addOption {
  width: 100%;
  margin-top: 12px;
  flex-shrink: 0;
}
