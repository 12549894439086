@import 'editor/src/comonStyles/global';

:global(.redux-toastr .top-right) {
  margin-top: 57px;
}

:global(body .redux-toastr .top-center) {
  margin-top: 57px;
  width: auto;
  margin-left: 0;
;
  transform: translate(-50%, 0);
}
