@import 'editor/src/comonStyles/variables';

.ScrollDownArrow {
  position: fixed;
  bottom: 0;
  left: 2px;
  height: 25px;
  width: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $black10;
  transition: scroll;
  background: rgba(255, 255, 255, 0.9);

  &:active {
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid $black10;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
}
