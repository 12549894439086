.wrapper {
  display: flex;
  gap: 8px;
  margin: 24px 0;
  flex-wrap: wrap;
}

.tabHeader {
  margin: 0;
}

.tabContent {
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}