@import 'editor/src/comonStyles/variables';

.tabs {
  background: $tabBackground;
  display: flex;
  flex-basis: 80px;
  flex-grow: 0;
  flex-direction: column;
  height: 100%;

  .fill {
    display: flex;
    flex-grow: 1;
    border-right: 1px solid $black5;
    background: $tabBackground;
  }
}
