.confirmationMenu {
  font-family: 'Gelato Sans', sans-serif;
  padding: 24px;

  .closeIconWrapper {
    display: flex;

    .closeIcon {
      cursor: pointer;
      margin-left: auto;
    }
  }

  .menuHeading {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    max-width: 90%;
  }

  .headerDescription {
    font-size: 16px;
    font-family: 'Gelato Sans', sans-serif;
    line-height: 24px;
  }

  .menuButtonsWrapper {
    display: grid;
    gap: 12px;
    padding-top: 12px;
    justify-items: stretch;

    .menuButton {
      display: flex;
      gap: 8px;
      width: 100%;
      border-radius: 24px;
    }
  }
}
