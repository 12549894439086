@import 'editor/src/comonStyles/variables';

.UploaderMobileMenu {
  height: 75vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: "Gelato Sans", sans-serif;
  font-size: 22px;
  margin: 0;
}

.uploaderPlaceholder {
  overflow: auto;
}