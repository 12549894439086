@import 'editor/src/comonStyles/variables';

.h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: $almostBlack;
  display: flex;
  align-items: center;
  padding-bottom: 13px;
  margin: 10px 0 0;

  &.noMargin {
    margin: initial;
  }

  &.noPadding {
    padding: initial;
  }

  &:not(.noRightMargin) div {
    margin-right: 16px !important;
  }
}
