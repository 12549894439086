@import 'editor/src/comonStyles/variables';

.ReorganizableList {
  position: relative;
}

.Element {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 0;
  opacity: 0;
  transition: opacity 100ms linear;
  user-select: none;
  will-change: transform;

  &.positioned {
    opacity: 1;
    transition: transform 200ms ease-out, opacity 100ms linear;
  }

  .children { flex: 1 0 0; }
}

.Element + .Element {
  padding-top: 24px;
}

.placeholder {
  pointer-events: none;
  width: 100%;
  display: inline-block;
  flex-shrink: 0;
}

.children {
  position: relative;
  z-index: 1;
}

.dragMarker {
  pointer-events: none;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  transition: background-color 100ms linear;

  &.showBorder {
    background-color: $buttonHoverBackground;
  }
}

.Handle {
  user-select: none;
  transition: background-color 100ms linear;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  width: 24px;
  height: 24px;

  svg {
    max-width: 20px;
    max-height: 20px;
  }

  &:hover {
    background: rgba(34, 34, 34, 0.1);
  }
}
