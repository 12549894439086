@import 'editor/src/comonStyles/index';

.personalizedLayerHeader {
  font-size: 16px;
  line-height: 20px;
  color: $black;
  font-weight: bold;
}

.personalizedLayerInfo {
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  font-weight: normal;
}

.nonPersonalizedItems {
  flex-grow: 1;
  overflow-y: auto;

  &.mobile {
    height: 100%;
    padding: 12px 12px 0;
  }
}
