@import 'editor/src/comonStyles/variables';

.dropDown {
  display: flex;
  flex-direction: column;
  justify-content: left;
  border-radius: 6px;

  :global(.button) {
    justify-content: left;
    padding: 0 5px;
    width: 100%;
    white-space: nowrap;

    > div {
      margin-right: 4px;
    }
  }

  .icon {
    svg {
      width: 24px;
      height: 24px;
    }

    display: flex;
    justify-content: center;
  }
}
