@import 'editor/src/comonStyles/variables';

.contentBlock {
  padding-left: 11px;
  padding-right: 11px;
  margin: 0 -11px;
  border-top: 1px solid $black5;
  background: $white;

  &.noBorder {
    border-top: none;
  }

  &.scroll {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.scrollAlways {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &.fill {
    height: 100%;
  }

  &.fillFlex {
    flex: 1 0 0;
  }

  &.noOverflow {
    overflow: hidden;
  }
}
