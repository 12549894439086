@import 'editor/src/comonStyles/variables';

.topMenuBar {
  background-color: $white;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  height: $mobileTopBarHeight;
  flex-shrink: 0;
  border-bottom: 1px solid #e9e9e9;

  > * {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-wrap: nowrap;

    &:nth-child(1) {
      justify-content: flex-start;
    }

    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      justify-content: flex-end;
    }
  }

  svg {
    max-width: 24px;
    max-height: 24px;
  }
}
