.HTMLDetails {
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }

  .content {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    p { margin-top: 16px; }
  }
}
