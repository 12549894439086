.MobileTextEditOverlay {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  z-index: 1000;
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-150%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: flex-start;

  &.visible {
    transform: translateY(0);
  }
}

.input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  resize: none;
  min-height: 40px;
  max-height: 96px;
  overflow-y: auto;
  line-height: 1.2;
  display: block;
  width: 100%;
}

.closeButton {
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #666;
  transition: color 0.2s ease;
  
  &:hover {
    color: #333;
  }
}
