.shopifyOptionContainer {
  border-radius: 6px;
  border: 1px solid #d4d4d4;
}

.badge {
  border-radius: 10px;
  padding: 2px 8px;
  align-items: center;
  color: white;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.32px;
  display: inline-block;
  margin-right: 10px;
  background: #6395df;
}

.badgeContainer {
  display: flex;
  align-items: center;
}

.label {
  font-size: 16px;
  font-weight: bold;
}

.header {
  font-weight: bold;
}

.subheader {
  color: #212121;
  font-size: 16px;
  line-height: 20px;
}
