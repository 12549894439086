@import 'editor/src/comonStyles/variables';

.TextEffectsContainer {
  border-radius: 6px;

  &:hover {
    background: #f5f5f5;
  }
}

.TextEffectEnableBackground {
  background: #f5f5f5;
}

.switchControl {
  flex-grow: 1;
}

.iconContainer {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
