@import 'editor/src/comonStyles/index';

.SidebarMobile {
  background: $white;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  z-index: 1;
  flex-direction: column;

  &.visible {
    display: flex;
  }

  transition: transform 300ms linear 300ms;

  &.hidden {
    pointer-events: none;
    transform: translateY(100%);
  }
}
