.productTitle {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 21px;
}

.iconInfoContainer {
  display: flex;
  margin-left: 4px;

  .iconInfo {
    cursor: pointer;
  }
}

.close {
  margin-right: 0;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
