@import 'editor/src/comonStyles/variables';

.variationGroupContainer {
  display: block;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  overflow: auto;

  &.selected {
    border-color: $neutral90;
    background: $neutral5;
  }
}

.widgetHeader {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-left: 10px;
  justify-content: space-between;
  width: 85%;

  .imageTitleContainer {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 80%;
  }

  .designOptionImage {
    border-radius: 2px;
    margin-right: 5px;
    width: 25%;

    .image {
      height: 40px;
      object-fit: fill;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 2px 2px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
    }
  }

  .header {
    margin-left: 5px;
    width: 100%;
  }
}

.list {
  position: relative;
  margin-left: 10px;
}

.variantEntry {
  position: relative;
  background: transparent;
}

.widget {
  padding: 0;

  .separator {
    margin: 0 10px;
    height: 1px;
    background: #e7e9eb;
  }
}
