@import 'editor/src/comonStyles/variables';

.MobileMenu {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ActionBarWrapper {
    padding: 12px 12px 0;
  }
}