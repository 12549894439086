@import 'editor/src/comonStyles/variables';

.ElementOperationOverlay {
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 1;
  border-radius: 5px;
  background-color: black;
  padding: 4px;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 10px;
  width: 80px;
  pointer-events: none;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.medium {
    background-color: $orange;
  }

  &.low {
    background-color: $destructive;
  }
}
