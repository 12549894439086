@import 'editor/src/comonStyles/variables';

.filtersWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.adjustmentSliderRow {
  padding: 10px;
  position: sticky;
  top: -1px;
  z-index: 2;
  background-color: $white;



  &.empty {
    display: none;
  }
}
