@import 'editor/src/comonStyles/variables';

.resetButton {
  width: 100%;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px solid $blue;
  }

  &.active {
    border: 1px solid $blue;
  }
}
