@import 'editor/src/comonStyles/variables';

.ConditionNameInput {
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 3px;
  padding: 7px 12px;
  font-size: 14px;
  line-height: 20px;

  &:focus {
    border-color: $blue;
    outline-color: $blue;
  }
}
