@import 'editor/src/comonStyles/variables';

.icon {
  border-radius: 4px;
  width: 24px;
  aspect-ratio: 1;

  &.shopifyIcon {
    background: #95BF47;
  }

  &.etsyIcon {
    background: #e16c35;
  }
}

.labelWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.apiContentTitle {
  font-size: 14px;
}

.apiContent {
  color: $neutral60;
  font-size: 12px;
}