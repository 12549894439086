@import 'editor/src/comonStyles/variables';

.shapePreset {
  width: 80px;
  height: 80px;
  cursor: pointer;
  position: relative;
  border: 1px solid $lightGrey;
  border-radius: 6px;
  text-align: center;
  touch-action: pan-y;

  &:hover {
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 2;
    }
  }
}
