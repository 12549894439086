@import 'editor/src/comonStyles/variables';

.SettingsMenuBtn {
  position: relative;
  cursor: pointer;
  transition: opacity 100ms linear;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
}

.dropdown {
  display: flex;
  flex-direction: column;
}

.SettingsMenuItem {
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-size: 13.3333px;
  cursor: pointer;
  height: 40px;
  padding: 0 5px;
  white-space: nowrap;

  &:hover {
    background: $buttonHoverBackground;
  }
}
