@import 'editor/src/comonStyles/variables';

.error {
  .info {
    border-radius: 3px;
    border: 1px solid $signalRed;
    background: rgba($signalRed, 0.1);
    padding: 10px;
    margin: 10px 0;
    font-size: 12px;
    line-height: 18px;
    color: $signalRed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .spinner {
      position: relative;
      flex: 0 0 60px;
    }

    .text {
      flex: 2 0;
      padding-left: 10px;
    }
  }

  .button {
    width: 100%;
  }
}
