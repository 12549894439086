@import 'editor/src/comonStyles/variables';

.MobileLayout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template:
    'embedded-header' auto
    'topMenu' auto
    'editorArea' 1fr
    'bottom-bar' auto
    / 1fr;
  position: relative;
}

.toggleOption {
  display: grid;
  justify-items: center;
  font-size: 11px;

  svg {
    stroke: none;
  }
}
