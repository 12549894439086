.overlay {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.24px;
  font-family: 'Gelato Sans', sans-serif;
  color: #383838;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .video {
    width: 320px;
    height: 170px;
    object-fit: cover;
    object-position: center;
    justify-self: stretch;
  }

  .container {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .title {
    font-weight: 700;
  }

  .description {
    font-weight: 400;
  }
}

.dropdown {
  min-width: 250px;
}
