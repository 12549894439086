.GalleryTabContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.dropZone {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -11px;
  right: -11px;
}

.addToDesign {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(100%); 
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &.visible {
    transform: translateY(0); 
    opacity: 1;
  }
}