@import 'editor/src/comonStyles/index';

.reuploadFailed {
  color: $almostBlack;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  .message {
    margin: 0 0 5px;
  }
}
