@import 'editor/src/comonStyles/variables';

.GalleryMobileMenu {
  height: 75vh;
  display: flex;
  flex-direction: column;
}

.galleryImageListWrapper {
  overflow-y: auto;
  flex: 1 0 0;
}

.title {
  font-family: "Gelato Sans", sans-serif;
  font-size: 22px;
  margin: 0;
}

.imageGalleryControls {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 10px;
  padding: 0 12px 10px;

  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
}


.BottomBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $white;
  transform: translateY(100%); 
  opacity: 0;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;

  &.visible {
    transform: translateY(0); 
    opacity: 0.98;
  }
}