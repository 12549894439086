@import 'editor/src/comonStyles/variables';

.switchTemplateButtonWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  overflow: visible;

  .button {
    border: 1px solid $neutral30;
    margin-left: 4px;
    white-space: nowrap;
  }
}
