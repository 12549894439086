@import 'editor/src/comonStyles/variables';

.actionBarSection {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $white;

  > :global(.button) {
    margin: 5px;

    span {
      padding: 0 4px;
    }
  }
}
