@import 'editor/src/comonStyles/variables';

.gridContainer {
  overflow: hidden;
  height: 80px;
  width: 80px;
  border: 1px solid $black300;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    border: 1px solid $blue;
  }

  &.selected {
    border: 2px solid $blue;
  }

  img {
    width: 250px;
    height: auto;
  }
}
