.MockupList {
  flex: 1 0 0;
  overflow-y: auto;

  .loader {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    svg {
      height: 30px;
      width: 30px;
    }
  }
}
