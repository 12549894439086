.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.title {
  font-family: "Gelato Sans", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-top: 24px;
  color: #212121;
}

.subtitle {
  font-family: "Gelato Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #6B6B6B;
  margin-top: 16px;
}

.link {
  color: #007CB4;
  font-family: "Gelato Sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 16px;
}
