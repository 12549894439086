.selector {
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 0;

  &:last-of-type {
    border-bottom: none;
  }
}

.selectorName {
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
