.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  .label {
    font-family: Roboto, 'sans-serif';
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    white-space: nowrap;
  }
}