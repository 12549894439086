@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.dropDownWrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
}

.ButtonFontSize {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .inputWrap {
    display: flex;
    justify-content: center;
    flex: 1 0 40px;
    position: relative;
    border-top: 1px solid $black10;
    border-bottom: 1px solid $black10;
    text-align: center;

    .input {
      width: 40px;
      height: 38px;
      border: none;
      outline: none;
      text-align: center;
      appearance: textfield;
      padding: 0;

      &::-webkit-outer-spin-button,&::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      &.wrongValue {
        background: color.mix($white, $signalRed, 90%);
      }
    }
  }

  .button {
    min-width: 40px;
    height: 40px;
    background: $white;
    border: none;
    padding: 0;
    cursor: pointer;
    border-top: 1px solid $black10;
    border-bottom: 1px solid $black10;
    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:not(.disabled):hover {
      background: $black5;
    }

    &:not(.disabled):active {
      background: $black10;
    }

    &.minusButton {
      border-left: 1px solid $black10;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;

      svg {
        fill: $almostBlack;
        stroke: none;
      }
    }

    &.valueButton {
      flex: 1 0 40px;
      text-align: center;
    }

    &.plusButton {
      border-right: 1px solid $black10;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;

      svg {
        fill: $almostBlack;
        stroke: none;
      }
    }
  }
}
