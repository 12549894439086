@import 'editor/src/comonStyles/index';

.listItem {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;

  img {
    max-height: 100%;
    max-width: 80%;
    object-fit: contain;
  }
}

.loader {
  svg {
    width: 5vh;
    height: 5vh;

    > * {
      stroke: $neutral80;
    }
  }
}
