@import 'editor/src/comonStyles/variables';

.label {
  font-size: 10px;
  margin: 12px 0;
  color: #707070;
}

.palette {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.opacity {
  display: flex;
}

.customColor {
  border-top: 1px solid rgba($black, 0.1);
}
