@import 'editor/src/comonStyles/variables';

.settingsItem {
  padding: 15px 0;
  border-bottom: 2px solid $black5;

  @mixin text {
    padding: 5px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
  }

  .description {
    @include text;

    color: $black70;
  }

  .title {
    @include text;

    color: $black;
  }

  svg {
    margin-top: 5px;
  }
}
