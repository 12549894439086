@import 'editor/src/comonStyles/variables';

.dropdownContainer {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.dropdownWrapper {
  position: relative;
}

.dropdown {
  position: absolute;
  border-radius: 6px;
  background-color: $white;
  padding: 12px;
  box-shadow: 10px 10px 22px -1px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
}

.fadeMask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  left: 0;
  top: 0;
  z-index: 998;
}

.fadeMask, .dropdown {
  transition: opacity 100ms linear;
}
