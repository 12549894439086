@import 'editor/src/comonStyles/variables';

.Sidebar {
  background: $white;
  display: flex;
  border-right: 1px solid $black5;
  position: relative;
  overflow: visible;
  flex-shrink: 0;
  grid-area: sidebar;

  * {
    &:not(input) {
      user-select: none;
    }
  }

  &.visible {
    width: 391px;
  }

  .closeButton {
    z-index: 1;
    display: flex;
    position: absolute;
    margin-top: 100%;
    background-color: $white;
    margin-left: 100%;
    width: 24px;
    height: 72px;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;

    &:hover {
      background: $buttonHoverBackground;
      border: 1px solid $buttonHoverBackground;

      &:active {
        background: $buttonActiveBackground;
        border: 1px solid $buttonActiveBorder;
      }
    }

    .closeButtonArea {
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .content {
    z-index: 0;
    flex: 1 0 0;
    position: relative;
  }
}
