@import 'editor/src/comonStyles/variables';

.MenuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1px;

  svg {
    margin-right: 9px;
    max-width: 24px;
    max-height: 24px;
  }
}

.MenuButton + .MenuButton {
  margin-top: 12px;
}

.buttonIconSettings svg { max-height: 28px; }

.buttonIconWarnings svg { max-height: 17px; }

.badge {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
