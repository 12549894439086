@import 'editor/src/comonStyles/index';

.technologyControl {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;

  > div {
    width: 50%;
    justify-content: center;
    margin-right: 0;
  }
  > div[class*="_active"] {
    color: $white;
    background-color: $neutral90;
    border-color: $neutral90;

    > div {
      color: $white;
    }
  }
}
.printTechnicControl {
  > div {
    width: 100%;
    margin-right: 0;
  }
}
.printTechnicTooltipBlock {
  height: 18px;
}
.singlePrintTechnicControl {
  > div {
    position: relative;

    &:before {
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      border-color: $neutral90;
      border-width: 2px;
      border-radius: 100%;
      border-style: solid;
      margin-right: 8px;
    }
    &[class*="_active"]:after {
      content: '';
      position: absolute;
      left: 14px;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: $neutral90;
    }
  }
}
