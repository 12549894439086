.scrollableArea {
  display: flex;
  overflow-x: auto;
  width: calc(100vw);
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  > * {
    margin: 0 0 0 12px;
  }
}
