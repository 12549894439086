@import 'editor/src/comonStyles/variables';

.pageSwitcherWrapper {
  display: inline-flex;
  place-self: center center;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: $white;
  border-radius: 24px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  .spreadName {
    text-align: center;
    color: $neutral70;
    font-size: 14px;
    min-width: 120px;
  }
}
