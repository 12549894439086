.drawerHeader {
  height: 52px;
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1;
}

.title {
  font-family: "Gelato Sans", sans-serif;
  font-size: 22px;
  margin: 0;
}
