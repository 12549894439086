@import 'editor/src/comonStyles/variables';

.saveDraftButtonWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  overflow: visible;

  .button {
    border: 1px solid $neutral30;
    margin: 0 4px;
    padding: 0 12px;
    white-space: nowrap;

    @media only screen and (max-width: 800px) {
      margin: 0 12px;
    }
  }

  .dropDown {
    position: absolute;
    top: 50px;
    right: 15px;
  }
}
