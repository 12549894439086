@import 'editor/src/comonStyles/index';

.colorDot {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  margin-right: 6px;
  margin-bottom: 6px;
  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:hover {
    border-color: #212121;
    background: rgba(#212121, 0.1);

    .color {
      border-color: #212121;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg path {
      stroke-width: 1;
      stroke: rgba($black50, 50);
    }
  }

  .color {
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  &.selected {
    border: 2px solid #212121;
    background: rgba(#212121, 0.1);

    .color {
      border-color: #212121;
    }

    &:hover {
      background: rgba(#212121, 0.2);
    }

    &:active {
      background: rgba(#212121, 0.3);
    }
  }

  &.disabled {
    opacity: 0.8;
    border-color: $black30;
    pointer-events: none;

    .color {
      border-color: $black30;
    }
  }
}

.unavailable {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
