@import 'editor/src/comonStyles/variables';

.textPanel {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;

  :global(.button) {
    margin: 4px;

    span {
      padding: 0 4px;
    }
  }
}
