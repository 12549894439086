@import 'editor/src/comonStyles/variables';

.container {
  position: relative;
}

.button {
  overflow: visible;
}

.dropDownWrapper {
  position: absolute;
  top: 50px;
  left: 0;
}

.dropDown {
  display: flex;
  flex-direction: column;
  justify-content: left;
  border-radius: 6px;
  padding: 8px;

  :global(.button) {
    justify-content: left;
    padding: 0 5px;
    width: 100%;
    white-space: nowrap;

    > div {
      margin-right: 5px;
    }
  }
}
