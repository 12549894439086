@import 'editor/src/comonStyles/variables';

.galleryImageList {
  user-select: none;
  width: 100%;
}

.VerticalMasonry {
  display: flex;
  position: relative;

  .column {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  }

  .column + .column {
    padding-left: 4px;
  }
}

.extraBottomPadding {
  position: absolute;
  width: 100%;
}