@import 'editor/src/comonStyles/variables';
.icon {
  svg {
    width: 24px;
    height: 24px;
  }
}

.img {
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-radius: 4px;
  border: 2px solid #E6E6E6;
}

.elementContainer {
  background: #f7f7f7;
  border: 1px solid $rulerBorderColor;
  border-radius: 6px;
  margin-bottom: 12px;
}