@import 'editor/src/comonStyles/variables';

.addonListItem {
  width: calc(25% - 6px);
  padding-bottom: calc(25% - 6px);
  margin: 3px;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  touch-action: pan-y;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
  }

  &:hover {
    background-color: $black10;
  }

  .badges {
    position: absolute;
    bottom: 5px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);
    z-index: 1002;
    align-items: flex-start;
  }

  .price, .free, .usage {
    border: 1px solid $signalOrange;
    height: 11px;
    border-radius: 7px;
    padding: 0 4px;
    margin-top: 3px;
  }

  .price {
    display: none;
    border-color: $signalOrange;
    color: $signalOrange;
    background: $white;
    font-size: 9px;
    line-height: 13px;
    white-space: nowrap;
  }

  .free {
    display: none;
    border-color: $black30;
    color: $black30;
    background: $white;
    font-size: 9px;
    line-height: 13px;
    text-transform: uppercase;
  }

  .premium {
    display: none;
    height: 18px;
    margin-top: 3px;

    svg {
      height: 100%;
    }
  }

  .usage {
    border-color: $blue;
    color: $blue;
    background: $white;
    font-size: 9px;
    height: 16px;
    text-transform: uppercase;
    line-height: 9px;
    display: flex;
    align-items: center;

    span {
      position: relative;
      top: 1px;
    }

    svg {
      display: block;
      width: 9px;
      height: 9px;
      fill: $blue;
      stroke: $blue;
      margin: 0 2px 0 0;
    }
  }

  &:hover, &.mobile {
    .price { display: block; }
    .free { display: block; }
    .premium { display: block; }
  }
}

.addonListItem:global(.draggableClone) {
  touch-action: none;
  padding: 0;

  .price,.free,.premium,.usage {
    display: none;
  }
}
