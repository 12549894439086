.PersonalizationBar {
  grid-area: bar;
  width: 328px;
  border-left: 1px solid #e6e6e6;
}

.title {
  color: #212121;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 4px;
}


.button {
  font-size: 14px;
  width: 100%;
}

.content {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;

  ul {
    margin: 0;
  }
}

.form {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  textarea {
    width: 100%;
    padding: 10px;
  }
}

.submit {
  width: 100%;
  font-size: 14px;
}
