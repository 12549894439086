@import 'editor/src/comonStyles/variables';

.infoBoxContainer {
  display: flex;
  padding: 24px 0;
}

.infoBox {
  font-size: 14px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #5178b4;
  background: #eef1fb;
  user-select: none;
  width: 100%;
  font-weight: 400;
  line-height: 20px;
  position: relative;

  p:first-child {
    margin: 0;
  }

  p:not(:first-child){
    margin-top: 14px;
    margin-bottom: 0;
  }

  :nth-child(2) {
    letter-spacing: -0.1px;
  }
}

.priceInfoTooltip {
  line-height: 21px;
}

.linkButton {
  color: $info50;
}

.imageCaption {
  font-size: 12px;
  font-style: italic;
  margin-bottom: 16px;
}
