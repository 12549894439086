@import 'editor/src/comonStyles/variables';

.title {
  font-size: 12px;
  line-height: 16px;
  color: $almostBlack;
  font-weight: 600;
}

.carousel {
  .showAll {
    color: $info50 !important;
  }
}
