@import 'editor/src/comonStyles/variables';

.SwitchButton {
  background-color: $lightGrey;
  width: 40px;
  min-width: 40px;
  height: 24px;
  border-radius: 12px;
  cursor: pointer;
  border: 2px solid $neutral65;
  padding: 2px 0;
  display: flex;
  transition: background-color 200ms linear;

  .in {
    width: 16px;
    height: 16px;
    background-color: $neutral65;
    border-radius: 8px;
    transform: translateX(4px);
    transition: transform 200ms linear, background-color 200ms linear;
  }

  &.on {
    background-color: $neutral90;
    border-color: $neutral90;

    .in {
      background-color: white;
      transform: translateX(18px);
    }
  }

  &.disabled {
    pointer-events: none;
    border-color: $neutral30;
    background-color: $neutral10;

    .in {
      background-color: $neutral30;
    }

    &.on {
      background-color: $neutral30;

      .in {
        background-color: white;
      }
    }
  }

  &:hover {
    border-color: #666;
  }
}
