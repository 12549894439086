@import 'editor/src/comonStyles/index';

.FlatPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: preview;
  overflow: hidden;
  user-select: none;

  .safeAreaInfoBlock {
    display: flex;
    color: $neutral60;
    font-size: 11px;
    margin: 18px;
    align-items: center;

    .infoIcon {
      width: 16px;
      height: 16px;
      align-items: center;
      margin-right: 6px;

      path {
        fill: $neutral60,
      }
    }
  }
}
