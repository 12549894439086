.sidebar {
  width: 310px;
  flex-shrink: 0;
  border-right: 1px solid whitesmoke;
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  overflow: hidden;
}

.content {
  position: relative;
  z-index: 0;
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: preview;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
