@import 'editor/src/comonStyles/variables';

.units {
  height: 30px;
}

.inputs {
  display: flex;
  margin-bottom: 4px;
  width: 100%;
}

.field {
  flex: 1 0 0;
}

.field + .field {
  margin-left: 8px;
}

.error {
  font-size: 12px;
  line-height: 16px;
  color: $red;
}

.description {
  margin-top: 14px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.infoIcon {
  margin-left: 10px;

  &:hover {
    opacity: 0.5;
  }
}
