.ButtonWarning {
  display: flex;
  align-items: center;
  height: 100%;

  &.button {
    cursor: pointer;

    &:hover .indicator {
      opacity: 0.8;
    }
  }
}
