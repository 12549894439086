@import 'editor/src/comonStyles/variables';

.button {
  position: relative;
  height: 80px;
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;

  &:hover {
    border-color: $signalBlue;
  }
}

.image {
  height: 100%;
}

.iconPremium {
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
