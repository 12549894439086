@import 'editor/src/comonStyles/variables';

.dropdown {
  border-radius: 6px;
  background-color: $white;
  padding: 12px;
  z-index: 999;
  box-shadow: 10px 10px 22px -1px rgba(0, 0, 0, 0.25);
}

.fadeMask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  left: 0;
  top: 0;
  z-index: 998;
}

.warningsWrapper {
  max-height: 40vh;
  overflow-y: auto;
  margin-bottom: 12px;
}
