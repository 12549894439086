@import 'editor/src/comonStyles/index';

.BottomBarMobile {
  grid-area: bottom-bar;

  :global(.icon) svg {
    max-width: 24px;
    max-height: 24px;
  }

  :global(.shapeIcon) svg {
    max-width: unset;
    max-height: unset;
  }
}

.mainMenu {
  position: relative;
  width: 100%;
  justify-content: center;
  z-index: 2;
  display: grid;
  height: 100%;
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.controlsSection {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 75px;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > div {
    pointer-events: auto;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}
