@import 'editor/src/comonStyles/variables';

.fontFamily {
  font-size: 18px;
  display: flex;
  cursor: pointer;
  color: #333;

  .box {
    padding: 4px 12px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-radius: 6px;
    border: 1px solid transparent;

    &:hover {
      background: rgba(51, 51, 51, 0.05);
    }
  }

  &.active .box {
    background: $neutral10;
    border-color: #212121;
  }

  img {
    height: 20px;
  }
}
