@import 'editor/src/comonStyles/variables';

.cropZoomActionBar {
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
}
