@import 'variables';
@import 'dnd';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@media only screen and (max-width: $maxMobileWidth) {
  body {
    user-select: none;
  }
}

button {
  all: unset;
  user-select: none;
  cursor: pointer;
}

body {
  font-family: Roboto, Arial, sans-serif;
}
