@import 'editor/src/comonStyles/index';

.autoFillButtonWrapper {
  width: 100%;
}

.buttonAutoFill {
  width: 100%;
  height: 48px;
  display: flex;
  gap: 8px;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 3px 0 12px;
  min-width: 100%;
  border-radius: 24px;
  border-color: transparent !important;
  background: transparent;

  &:not([disabled]):hover {
    background: linear-gradient(90deg, #82BAD633, #997EB133, #9C77AC33) !important;
  }

  .autoFillLabelWrapper {
    display: inline;
    background: linear-gradient(90deg, #547AB7, #473881);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-decoration: none;

    .title {
      background: inherit;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .icon {
    background: linear-gradient(90deg, #3592C1, #547AB7);
    -webkit-mask: url('../../../../Autofill/icon-photobook.svg') no-repeat center;
    mask: url('../../../../Autofill/icon-photobook.svg') no-repeat center;
    mask-size: contain;
    width: 24px;
    height: 24px;
  }
}
