@import 'editor/src/comonStyles/variables';

.radio {
  display: inline-flex;
  place-items: center center;
  cursor: pointer;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: $almostBlack;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: $black10;
  }
}

.radioButtonElement {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $almostBlack;
  border: 1px solid $black30;
  border-radius: 6px;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 10px;

  &.active, &.enabled:hover {
    background-color: $neutral10;
    border: 1px solid #212121;
  }
}
