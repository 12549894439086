.arrow {
  height: 100%;
  width: 24px;
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  z-index: 1000;
  border: 0;
  cursor: pointer;
}

.arrowLeft {
  background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0.71) 43.75%, rgba(255, 255, 255, 0) 76.56%);
}

.arrowRight {
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.71) 43.75%, #fff 76.56%);
}
