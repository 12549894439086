@import 'editor/src/comonStyles/variables';

.schemaVertical {
  width: 27%;
}

.schemaHorizontal {
  width: 45%;
}

.schema {
  cursor: pointer;
  margin: 0 4px 12px;
  box-shadow: 0 0 0 1px #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;

  @media only screen and (max-width: 800px) {
    margin: 0 6px 12px;
    height: 76px;
    width: auto;

    &:last-of-type {
      margin-right: 20px;
    }
  }

  &:hover {
    box-shadow: 0 0 0 2px $primary100, inset 0 0 0 2px #fff;
  }

  &.active {
    box-shadow: 0 0 0 2px $primary100, inset 0 0 0 2px #fff;
  }
}

.schemaPreview {
  width: 100%;
  height: 100%;

  @media only screen and (max-width: $maxMobileWidth) {
    height: 100%;
    width: auto;
  }
}
