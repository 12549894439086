@import 'editor/src/comonStyles/variables';

.heading {
  text-align: center;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
}

.subheading {
  color: rgba(53, 53, 53, 0.6);
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}
