@import 'editor/src/comonStyles/variables';

.prompt {
  margin: 12px 0;
}

.footer {
  font-size: 10px;
  line-height: 12px;
  border-top: 1px solid $black300;
}

.linkButton {
  color: $blue;
  outline: none;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
