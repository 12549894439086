@import 'editor/src/comonStyles/variables';

.ErrorMessageAccordion {
  background-color: #FFEFEB;
  position: sticky;
  bottom: 5px;

  @media only screen and (max-width: $maxMobileWidth) {
    min-height: 44px;
    display: grid;

    & > * {
      margin-top: auto;
    }
  }
}

.accordionHeader {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;

  .errorIcon {
    margin-left: auto
  }
}