@import 'editor/src/comonStyles/variables';

.FiltersMobileMenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.title {
  font-family: "Gelato Sans", sans-serif;
  font-size: 22px;
  margin: 0;
}

.filtersListWrapper {
  overflow-y: auto;
  flex: 1 0 0;
}