.RangeControl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.label {
  color: #333;
  margin: 12px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  flex: 1 0 0;
}

.input {
  width: 40px;
  height: 30px;
  padding: 0;
  border-color: hsl(0deg, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
}

.slider {
  flex: 1 0 100%;
}
