@import 'editor/src/comonStyles/variables';

.wrapper {
  display: inline-flex;
}

.tooltip {
  display: flex;
  position: fixed;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  transition: opacity 100ms linear;

  &.hidden {
    opacity: 0;
  }

  .arrow {
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &.bottom {
    flex-direction: column;

    .arrow {
      content: '';
      border-left-width: 5px;
      border-right-width: 5px;
      border-bottom: 5px solid $almostBlack;
    }
  }

  &.top {
    flex-direction: column-reverse;

    .arrow {
      content: '';
      border-left-width: 5px;
      border-right-width: 5px;
      border-top: 5px solid $almostBlack;
    }
  }

  &.default .overlay {
    background: $almostBlack;
    text-align: center;
    min-height: 0;
    padding: 6px 10px;
    font-size: 11px;
    color: $white;
    border-radius: 6px;
    box-shadow: 0 0 4px rgb(0, 0, 0, 0.17);
  }

  &.rich .overlay {
    border-radius: 12px;
    border: 1px solid #f2f2f2;
    background: #fff;
    box-shadow: 0 1px 3px 1px rgba(33, 33, 33, 0.15), 0 1px 2px 0 rgba(33, 33, 33, 0.3);
  }
}

.overlay {
  width: max-content;
  max-width: 300px;
}

.tooltipContainer {
  position: absolute;
  z-index: 1700;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  pointer-events: none;
}
