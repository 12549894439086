@import 'editor/src/comonStyles/variables';

.backButton:hover {
  text-decoration: underline;
  cursor: pointer;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid $black5;

  .image {
    max-width: 80%;
    margin-bottom: 10px;
  }

  .description {
    font-size: 16px;
  }

  &.padding {
    padding: 10px 0;
  }
}
