@import 'editor/src/comonStyles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and (max-width: $maxMobileWidth) {
    padding: 0 12px;
  }

  .resetButton {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
