@import 'editor/src/comonStyles/variables';

.header {
  display: flex;
  align-items: center;
  height: 57px;
  flex-shrink: 0;
}

.filterInput {
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 3px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 12px;

  input {
    height: 40px;
    padding: 0 12px;
    border: 1px solid transparent;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    color: $black;
    border-radius: 6px;
    outline: none;

    &:hover {
      background: rgba(51, 51, 51, 0.05);
    }
  }
}

.groupedFonts {
  border-bottom: 1px solid $lightGrey;
}

.sectionTitle {
  font-size: 16px;
  font-weight: 700;
  padding: 16px 12px;
}

.fontsContainer {
  .collapsableContainer {
    border: 0;
    padding: 4px 0;
    margin: 0;
  }

  .collapsableHeader {
    font-size: 16px;
    font-weight: 700;
    padding: 16px 12px;
  }
}
