@import 'editor/src/comonStyles/variables';

.filtersAndSearch {
  margin-bottom: 10px;
  display: flex;
}

.filtersCounterBadge {
  bottom: 0;
  left: 0;
  top: initial;
  background-color: $blue;
}
