@import 'editor/src/comonStyles/variables';

.sizeProperty {
  display: flex;
  flex-direction: row;

  .inputs {
    width: 70%;

    .inputWrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0;

      .input {
        margin-left: 10px;
      }
    }
  }

  .lock {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .lockButton {
      margin: 0 !important;
    }
  }
}
