@import 'editor/src/comonStyles/variables';

.OptionsDropDownBtn {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
  padding: 8px 10px;

  span {
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 5px;
    color: $blue;
  }

  &:hover {
    background-color: $buttonHoverBackground;
  }
}

.dropDownOption {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  padding: 10px 15px;

  &:hover, &.active {
    background-color: $buttonHoverBackground;
  }
}
