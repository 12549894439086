.calendarEventsHeader {
  display: none;

  svg {
    margin-right: 16px;
  }

  @media (min-width: 500px) {
    display: block;
  }
}

.calendarEventsTabContent {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  font-family: "Gelato Sans";

  .showCalendarEventsToggle {
    display: flex;
    padding-top: 12px;
    font-size: 14px;
    gap: 12px;
    align-items: center;
  }
}

