@import 'editor/src/comonStyles/variables';

.ElementsSection {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 12px 12px;
  width: 100%;
}

.empty {
  background: #f4f6f8;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  color: #b3b3b3;
  user-select: none;
}

.elements {
  flex: 1 0 0;
  width: 100%;
  background: #f4f6f8;
  display: flex;
  flex-direction: column;
}

.element {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  padding: 10px;
}

.label {
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.link {
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  color: $blue;
  margin-left: 5px;

  &.isLinked {
    color: red;
  }
}
