.EmptyPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms linear;
}

.container {
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  img {
    margin: 0 30px;
  }
}

.textBox {
  margin: 30px;
  width: 440px;
}

.description {
  margin-top: 24px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
}
