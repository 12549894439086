.HorizontalMasonry {
  position: relative;

  > * {
    position: absolute !important;
    opacity: 0;
    z-index: 0;
    transition: opacity 100ms linear;
  }
}
