.fontSize {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 90px;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
}

.dropdown {
  max-height: 250px;
}

.alignmentButtons {
  display: flex;
}
