@import 'editor/src/comonStyles/variables';

.widget {
  background-color: $lighterBlue;
}

.info {
  line-height: 16px;
  font-size: 12px;
  max-height: inherit;
}

.unAvailableVariantsBlock {
  background-color: $white;
  border-radius: 4px;
  margin: 10px 0 0;
  padding: 10px;

  >:last-child {
    border: none;
  }
}

.group {
  margin: 7px 0;
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid $lightGrey;
}

.groupName {
  text-align: center;
  align-items: flex-start;
  display: flex;
  width: 20%;
}

.groupValue {
  display: flex;
  flex-flow: row wrap;
  width: 70%;
}

.colorDot {
  display: inline-block;
  cursor: auto;
  border: none;
  margin: 0 -15px 0 0;
  padding: 0;

  &:hover {
    border: none;
    background: none;
  }
}
