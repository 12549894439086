@import 'editor/src/comonStyles/variables';

.ConditionValueBuilder {
  display: flex;
  align-items: center;
  margin-top: 5px;

  input {
    flex: 1 0 0;
    border: 1px solid rgba(51, 51, 51, 0.2);
    border-radius: 3px;
    font-size: 16px;
    line-height: 20px;
    padding: 7px 12px;

    &:focus {
      border-color: $blue;
      outline-color: $blue;
    }
  }

  .thumbnail {
    object-fit: contain;
  }

  .emptyThumbnail {
    background-color: #f4f4f4;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #e6e6e6;
    }
  }

  .uploadIcon {
    stroke: $blue;
  }

  .emptyThumbnail, .thumbnail {
    margin-right: 5px;
    height: 36px;
    width: 36px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .remove {
    margin-left: 5px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}
