@import 'editor/src/comonStyles/variables';

.buttonPremium {
  height: 22px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $ube;
  border-radius: 11px;

  svg {
    height: 8px;
    width: auto;
    margin-right: 5px;
  }

  color: white;
  font-size: 9px;
  font-weight: 500;

  &:hover {
    opacity: 0.5;
  }
}
