@import 'editor/src/comonStyles/variables';

.ProductVariantEntry {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transition: opacity 200ms linear;
  background-color: white;

  .content {
    border: 1px solid transparent;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    margin: 3px 0;
  }

  &.selected .content {
    border-color: $primary100;
    background-color: $neutral20;
  }

  &:hover .content {
    background-color: $neutral20;
  }

  &.positionned {
    transition: transform 300ms ease-out, opacity 200ms linear;
  }

  .deleteIcon {
    flex-shrink: 0;
    padding: 2px 4px;
    margin-right: 8px;
    cursor: pointer;
    min-height: 28px;
    align-items: center;
    display: flex;

    &.disabled {
      opacity: 0.45;
      pointer-events: none;
    }
    svg {
      height: 18px;
      width: 18px;
    }
  }
}

.entryContent {
  display: flex;
  align-items: center;
  position: relative;

  &.outOfStock::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    background: $destructive70;
    border-radius: 50%;
  }
}

.thumbnail {
  flex-shrink: 0;
  margin: 0 8px 0 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  img {
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 2px 2px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  }
}

.entryText {
  flex: 1 0 0;
  color: $almostBlack;
}

.entryOutOfStock, .entryUnavailable {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: $destructive70;
  margin-top: 11px;
}

.unavailable {
  color: $grey;
  text-decoration: line-through;
}
