@import 'editor/src/comonStyles/variables';

.AIToggle {
  border-radius: 6px;
  border: 1px solid #d4d4d4;
}

.badge {
  border-radius: 10px;
  background: #189a7b;
  padding: 2px 8px;
  align-items: center;
  color: white;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.32px;
}

.content {
  ol {
    margin: 0;
  }

  color: #333;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  margin-top: 8px;
}

.testButton {
  width: 100%;
  margin-top: 4px;
}

.info {
  display: flex;
}

.badgeContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.switchContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.switch {
  display: flex;
}

.label {
  font-size: 16px;
  font-weight: bold;
}

.subheader {
  color: #6B6B6B;
  font-weight: normal;
}

.learnMoreLink {
  display: inline-block;
  color: $info50;
  font-size: 14px;
  margin-top: 4px;
}
