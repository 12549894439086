.Alert {
  padding: 15px;
  font-size: 14px;
  color: rgba(208, 65, 45, 1);
  border: 1px solid rgba(208, 65, 45, 1);
  border-radius: 8px;
  background-color: rgba(255, 228, 221, 1);
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  margin: 0 auto;
  min-width: 250px;
  max-width: 80%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
