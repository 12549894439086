@import 'editor/src/comonStyles/variables';

.searchBlock {
  position: relative;
  flex-grow: 1;

  .searchInput {
    width: 100%;
    height: 38px;
    border: 1px solid $black30;
    color: $almostBlack;
    border-radius: 6px;
    font-size: 16px;
    align-items: start;

    input {
      border: none;
      outline: none;
      background: transparent;
      padding-right: 44px;
    }

    ::placeholder {
      color: $black70;
    }

    &:focus {
      border-color: $blue;
      outline: none;
    }
  }

  .button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 6px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 38px;

    circle {
      fill: none;
    }
  }
}
