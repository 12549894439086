@import 'editor/src/comonStyles/variables';

.container {
    padding: 10px 12px;
    margin: 12px 0;
    border: 1px solid $neutral70;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}