@use 'sass:color';
@import 'editor/src/comonStyles/variables';

.dropdownButtonContainer {
  flex-grow: 1;
}

.dropdownButton {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $lightGrey;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background: color.mix($white, $black, 95%);
  }

  .dropdownColorTile {
    margin-right: 8px;
  }

  .arrow {
    margin-left: auto;
  }

  &.opened {
    .arrow {
      transform: rotate(180deg);
    }
  }
}

.displayDropdown {
  border: 1px solid $lightGrey;
  box-shadow: 0 3px 4px 0 rgba(33, 33, 33, 0.12), 0 2px 6px 0 rgba(33, 33, 33, 0.10);
  margin-top: 4px;
  z-index: 1700;
}

.dropdownContent {
  flex-grow: 1;

  .hexCodeContainer {
    font-size: 14px;
  }
}

:global(#dropdown-container-id:has(.thread-color-dropdown)) {
  z-index: 1700;
}
