.dropdown {
  display: block !important;
  min-width: 250px;

  .half {
    flex-direction: column;
    display: inline-flex;
    width: 50%;
  }
}

.button span { padding: 0 4px; }
