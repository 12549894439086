@import 'editor/src/comonStyles/variables';

.ScrollRightArrow {
  position: fixed;
  height: 51px;
  right: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $black10;
  transition: scroll;
  background: rgba(255, 255, 255, 0.9);

  &:active {
    background: rgba(255, 255, 255, 0.9) !important;
    border: 1px solid $black10 !important;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
}
