@import 'editor/src/comonStyles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;


  @media only screen and (max-width: $maxMobileWidth) {
    padding: 0 16px;
    padding-bottom: 60px;
  }
}