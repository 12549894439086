@import 'editor/src/comonStyles/variables';

.AbstractFoilLayerItem {
  border-radius: 6px;
  border: 1px solid $lightGrey;
  display: flex;
  padding: 12px 8px;
  min-height: 32px;
  width: 100%;
  align-items: center;

  &:hover .element {
    background-color: $buttonHoverBackground;
  }

  &.selected .element {
    background-color: $neutral10;
    border-color: #212121;

    &:hover {
      background-color: $buttonActivatedHoverBackground;
    }
  }

  .left {
    display: flex;
    align-items: center;
    height: 40px;
    flex: 1 0 0;
    overflow: hidden;
  }

  .right {
    height: 100%;
    margin-left: 3px;
    display: flex;
    align-items: center;
  }
}
